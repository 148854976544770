import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { fetchAllCatalogs } from '../api';
import { CatalogsInitialState } from './interfaces';

const initialState: CatalogsInitialState = {
  catalogs: [],
};
const catalogsSlice = createSlice({
  name: 'catalogs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all catalogs
    builder.addCase(fetchAllCatalogs.pending, (state) => {
      state.catalogs = [];
    });
    builder.addCase(fetchAllCatalogs.fulfilled, (state, action) => {
      state.catalogs = action.payload;
    });
    builder.addCase(fetchAllCatalogs.rejected, (state) => {
      state.catalogs = [];
    });
  },
});
export const {} = catalogsSlice.actions;
export default catalogsSlice.reducer;
