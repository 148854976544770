import { useState } from 'react';
import { Input } from 'shared/ui/Input';

import styles from './NewsBanner.module.scss';
import { ReactComponent as SearchSVG } from '../../../shared/assets/icons/search.svg';

export const NewsBanner = () => {
  const [value, setValue] = useState('');
  return (
    <div className={styles.container}>
      <p className={styles.title}>Новости</p>
      <Input
        tip="Поиск"
        tipAsPlaceholder
        additionalClasses={styles.input}
        value={value}
        setValue={setValue}
        type={2}
        iconRight={<SearchSVG />}
      />
    </div>
  );
};
