import { NewsBanner } from 'widgets/News/NewsBanner/NewsBanner';
import { NewsBlock } from 'widgets/News/NewsBlock/NewsBlock';

import styles from './News.module.scss';

const News = () => {
  return (
    <div style={{ width: '100%' }}>
      <NewsBanner />
      <NewsBlock />
    </div>
  );
};

export default News;
