import { ChatRoomT } from 'entities/chats/types';

import styles from './ChatStatusBar.module.scss';

export const ChatStatusBar = ({ status }: { status: ChatRoomT['status'] }) => {
  return (
    <>
      {status && (
        <div className={styles.bar}>
          <div className={`${styles['statuses-block']} ${styles[status]}`}>
            <span>{'Общение'}</span>
            <span>{'Оплата'}</span>
            <span>{'Встреча'}</span>
            <span>{'Завершение'}</span>
          </div>
        </div>
      )}
    </>
  );
};
