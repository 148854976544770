import { Modal } from 'shared/ui/Modal';
import { getFormatedDate } from 'shared/helpers/getFormatedDate/getFormatedDate';

import styles from './MembersModal.module.scss';
import { MembersModalPropsI } from './MembersModal.props';

export const MembersModal = ({ isVisible, close }: MembersModalPropsI) => {
  const operationsList = [
    { date: '2023-12-22T11:32:27.181Z', time: '15:18', sum: 500, nickname: 'sjkhdj_007', id: 1 },
    { date: '2023-12-22T11:32:27.181Z', time: '15:18', sum: 500, nickname: 'sjkhdj_007', id: 2 },
    { date: '2023-12-22T11:32:27.181Z', time: '15:18', sum: 500, nickname: 'sjkhdj_007', id: 3 },
    { date: '2023-12-22T11:32:27.181Z', time: '15:18', sum: 500, nickname: 'sjkhdj_007', id: 4 },
    { date: '2023-12-22T11:32:27.181Z', time: '15:18', sum: 500, nickname: 'sjkhdj_007', id: 5 },
    { date: '2023-12-22T11:32:27.181Z', time: '15:18', sum: 500, nickname: 'sjkhdj_007', id: 6 },
    { date: '2023-12-22T11:32:27.181Z', time: '15:18', sum: 500, nickname: 'sjkhdj_007', id: 7 },
    { date: '2023-12-22T11:32:27.181Z', time: '15:18', sum: 500, nickname: 'sjkhdj_007', id: 8 },
    { date: '2023-12-22T11:32:27.181Z', time: '15:18', sum: 500, nickname: 'sjkhdj_007', id: 9 },
  ];
  return (
    <Modal isVisible={isVisible} close={close}>
      <p className={styles.title}>Участники и ставки</p>
      <ul className={styles.list}>
        {operationsList.map(({ date, time, sum, nickname, id }) => (
          <li key={id} className={styles.item}>
            <span className={styles.date}>{getFormatedDate(date)}</span>
            <span className={styles.date}>{time}</span>
            <span className={styles.nickname}>{nickname}</span>
            <span className={styles.sum}>+ {sum}$</span>
          </li>
        ))}
      </ul>
    </Modal>
  );
};
