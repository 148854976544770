import { useState } from 'react';
import { Tabs } from 'shared/ui/Tabs';

import styles from './AdminPage.module.scss';
import { AdminBotTab } from './Tabs/AdminBotTabs/AdminBotTab';
import { NotificationsBot } from './Tabs/NotificationsBot';

const AdminPage = () => {
  const [token, setToken] = useState('');
  const tabs = [
    {
      content: <AdminBotTab token={token} />,
      label: 'Общие настройки',
      id: 0,
    },
    {
      content: <NotificationsBot token={token} />,
      label: 'Уведомления',
      id: 1,
    },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Код доступа: </h1>
        <input
          className={styles.token_input}
          onChange={(event) => setToken(event.target.value)}
          type="text"
          placeholder="Введите токен"
        />
      </div>
      <Tabs tabs={tabs} />
    </div>
  );
};

export default AdminPage;
