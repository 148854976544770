import { Link } from 'react-router-dom';

import { CatalogOperationType, OperationType, QuestionnairyOperationType } from '../../types';

export function generateOperationLink(o: OperationType<any>) {
  switch (o.type) {
    case 'pre-order-catalog':
    case 'catalog': {
      const o2: OperationType<CatalogOperationType> = o;
      return <Link to={`#${o2.details.id}`}>&quot;{o2.details.title['ru']}&quot;</Link>;
    }

    case 'questionnaire': {
      const o2: OperationType<QuestionnairyOperationType> = o;
      return (
        <Link to={`#${o2.details.id}`}>
          &quot;{o2.details.firstName}, {o2.details.age}&quot;
        </Link>
      );
    }
    default:
      return <></>;
  }
}

export function generateOperationText(o: OperationType<any>) {
  switch (o.type) {
    case 'catalog': {
      return 'покупка каталога';
    }
    case 'donation': {
      return 'донат';
    }
    case 'pre-order-catalog': {
      return 'предварительная оплата каталога';
    }
    case 'questionnaire': {
      return 'покупка анкеты';
    }
    case 'selection': {
      return 'подбор девушки';
    }
    case 'payment':
    default:
      return <></>;
  }
}
