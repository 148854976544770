import { UserStatusWidget } from 'widgets/Users/UserStatusWidget';
import { OperationsTable } from 'entities/operations/ui/OperationsTable';

import styles from './ProfileTab.module.scss';
export const ProfileTab = () => {
  return (
    <div className={styles.tab}>
      <UserStatusWidget />
      <OperationsTable additionalClasses={styles.table} />
    </div>
  );
};
