import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL, instance } from 'shared/config/api/api';

import { CatalogsResponse, CreateCatalogI } from '../types';

export const catalogApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      // Add your authorization logic here
      headers.set('Authorization', `Token awdkjrnfernferifnriefnicmoi`);

      return headers;
    },
  }),
  endpoints: (builder) => ({
    createCatalog: builder.mutation({
      query: (catalog: CreateCatalogI) => ({
        url: '/create-catalog',
        method: 'POST',
        body: catalog,
      }),
    }),
    getAllCatalogs: builder.query({
      query: (string) => string,
    }),
  }),
});

export const fetchAllCatalogs = createAsyncThunk(
  'catalogs/all',
  async (value: boolean | undefined) => {
    const isBuyed = value ? '?isBuyed=true' : '';
    const response = await instance.get<CatalogsResponse>(`${BASE_URL}/catalogs${isBuyed}`);

    const normalizedCatalogs = response.data.catalogs.map((item) => {
      if (item.price) {
        return {
          ...item,
          price: item.price / 100,
        };
      } else {
        return item;
      }
    });

    return normalizedCatalogs;
  }
);

export const { useCreateCatalogMutation, useGetAllCatalogsQuery } = catalogApi;
