import { useRef, useState } from 'react';
import { Input } from 'shared/ui/Input';
import { Checkbox } from 'shared/ui/Checkbox';
import { Button } from 'shared/ui/Button';
import { notificationsBotApi } from 'shared/config/api/api';
import { NotificationBotI } from 'pages/AdminPage/interfaces';
import { useFocus } from 'shared/hooks/useFocus';

import styles from './CreateNotification.module.scss';
import { Stipulation } from '../Stipulation/Stipulation';

export interface CreateNotificationPropsI {
  token: string;
}

export const CreateNotification = ({ token }: CreateNotificationPropsI) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [qParams, setQparams] = useState<NotificationBotI>({
    name: '',
    text: '',
    period: '',
    stipulation: { buyed: true },
  });

  const [errors, setErrors] = useState({
    nameError: false,
    textError: false,
    periodError: false,
  });

  const [isFocused] = useFocus(textareaRef);

  const getIncludesValue = () => {
    let newErrors = { ...errors };

    if (!qParams.name) {
      newErrors = { ...newErrors, nameError: true };
    } else {
      newErrors = { ...newErrors, nameError: false };
    }
    if (!qParams.text) {
      newErrors = { ...newErrors, textError: true };
    } else {
      newErrors = { ...newErrors, textError: false };
    }
    if (!qParams.period) {
      newErrors = { ...newErrors, periodError: true };
    } else {
      newErrors = { ...newErrors, periodError: false };
    }

    setErrors(newErrors);
  };

  const handleCreateNotification = () => {
    if (qParams.name === '' || qParams.text === '' || qParams.period === '') {
      getIncludesValue();
    } else {
      notificationsBotApi.createBotNotification(qParams, token);
      setQparams({
        name: '',
        text: '',
        period: '',
        stipulation: { buyed: false },
      });
      setErrors({ nameError: false, textError: false, periodError: false });
    }
  };

  return (
    <div className={styles.create_notification}>
      <div className={styles.list}>
        <div>
          <Input
            tip="Имя отправителя"
            tipAsPlaceholder
            additionalClasses={styles.input}
            setValue={(event) => setQparams({ ...qParams, name: event })}
            value={qParams.name}
            type={2}
          />
          {errors.nameError && <p>Поле не может быть пустым</p>}
        </div>
        <div className={styles.textarea_container}>
          <textarea
            ref={textareaRef}
            className={styles.text_textarea}
            value={qParams.text}
            onChange={({ target }) => setQparams({ ...qParams, text: target.value })}
          ></textarea>
          <span className={`${styles.tip} ${isFocused && styles.tip_active}`}>
            Текст уведомления
          </span>
          {errors.textError && <p>Поле не может быть пустым</p>}
        </div>
        <div>
          <Input
            tip="Время (в минутах)"
            tipAsPlaceholder
            additionalClasses={styles.input}
            setValue={(event) => setQparams({ ...qParams, period: event })}
            value={qParams.period.replace(/\D/g, '')}
            type={2}
          />
          {errors.periodError && <p>Поле не может быть пустым</p>}
        </div>
        <Stipulation disabled={false} qParams={qParams} setQparams={setQparams} />
      </div>
      <Button onClick={handleCreateNotification} additionalClasses={styles.button} type={3}>
        Создать
      </Button>
    </div>
  );
};
