import { BadgeTypeT } from '../../../../shared/ui/Badge/Badge.props';
import { OrderCardList } from '../../../../widgets/Orders/OrderCardList';
import MockImage from '../../../../shared/assets/mock/Rectangle.png';

export const OrderTab = () => {
  const DetailsOrders = [
    {
      name: 'Мария',
      age: 24,
      classname: 'camel' as BadgeTypeT,
      title: 'vip',
      image: MockImage,
      status: 'communication',
      city: 'Россия',
      country: 'Москва',
      date: '2023-12-22T11:32:27.181Z',
      id: 1,
    },
    {
      name: 'Мария',
      age: 24,
      classname: 'camel' as BadgeTypeT,
      title: 'vip',
      image: MockImage,
      status: 'communication',
      city: 'Россия',
      country: 'Москва',
      date: '2023-12-22T11:32:27.181Z',
      id: 2,
    },
    {
      name: 'Мария',
      age: 24,
      classname: 'camel' as BadgeTypeT,
      title: 'vip',
      image: MockImage,
      status: 'communication',
      city: 'Россия',
      country: 'Москва',
      date: '2023-12-22T11:32:27.181Z',
      id: 3,
    },
    {
      name: 'Мария',
      age: 24,
      classname: 'camel' as BadgeTypeT,
      title: 'vip',
      image: MockImage,
      status: 'communication',
      city: 'Россия',
      country: 'Москва',
      date: '2023-12-22T11:32:27.181Z',
      id: 4,
    },
  ];
  return <OrderCardList detailsOrders={DetailsOrders} />;
};
