import { ReferalsListItem } from '../ReferalsListItem';
import styles from './ReferalsList.module.scss';

const data = [
  { id: 1, name: 'jsjdi_ooo', registerDate: new Date(), sum: 48000, bonus: 500 },
  { id: 2, name: 'jsjdi_ooo', registerDate: new Date(), sum: 48000, bonus: 500 },
  { id: 3, name: 'jsjdi_ooo', registerDate: new Date(), sum: 48000, bonus: 500 },
  { id: 4, name: 'jsjdi_ooo', registerDate: new Date(), sum: 48000, bonus: 500 },
  { id: 5, name: 'jsjdi_ooo', registerDate: new Date(), sum: 48000, bonus: 500 },
  { id: 6, name: 'jsjdi_ooo', registerDate: new Date(), sum: 48000, bonus: 500 },
  { id: 7, name: 'jsjdi_ooo', registerDate: new Date(), sum: 48000, bonus: 500 },
  { id: 8, name: 'jsjdi_ooo', registerDate: new Date(), sum: 48000, bonus: 500 },
];

export const ReferalsList = () => {
  return (
    <ul className={styles.list}>
      {data.map(({ id, name, registerDate, sum, bonus }) => (
        <ReferalsListItem
          key={id}
          name={name}
          registerDate={registerDate}
          sum={sum}
          bonus={bonus}
        />
      ))}
    </ul>
  );
};
