// import { useState } from 'react';

// import { useAppSelector } from '@/app/store/hooks';
// eslint-disable-next-line import/order
import { generateSubscriptionCard } from 'entities/subscriptions/helpers/generateSubscriptionCard';
import { SubscriptionControl } from 'entities/subscriptions/ui/SubscriptionControl';
import { SubscriptionT } from 'entities/subscriptions/types';
import { useState } from 'react';

import styles from './SubscriptionsWidget.module.scss';
import { CancelSubscriptionModal } from '../CancelSubscriptionModal';
import { CancelSubscriptionModalT } from '../CancelSubscriptionModal/CancelSubscriptionModalT';

const subscriptions: Array<SubscriptionT<any>> = [
  {
    id: 16,
    type: 'questionnaire-hot',
    start: '2023-12-11T18:30:03.995Z',
    end: '2024-12-11T18:30:03.995Z',
    autoDebit: true,
    details: {
      id: 3,
      firstName: 'Фаа',
      age: 53,
      priceHotSubscription: 5000,
      city: {
        id: 4,
        countryId: 2,
        country: {
          id: 2,
          name: {
            ru: 'США',
            en: 'USA',
            de: '',
          },
        },
        name: {
          ru: 'Сан-Франциско',
          en: 'San Francisco',
          de: '',
        },
      },
      catalog: {
        id: 4,
        classname: 'silver',
        title: {
          ru: 'Turkey',
          en: 'Turkey',
          de: '',
        },
      },
      avatars: [
        {
          id: 7,
          type: 'avatar',
          file: {
            id: 1,
            preview:
              '326f21fd-26c3-45de-9348-bfce78bc0be7-e6185abf-72e4-4bdf-aa54-8a178d4d5a74.jpg',
          },
        },
      ],
    },
    price: 5000,
  },
  {
    id: 17,
    type: 'questionnaire-hot',
    start: '2023-12-11T18:30:03.995Z',
    end: '2024-12-11T18:30:03.995Z',
    autoDebit: true,
    details: {
      id: 4,
      firstName: 'Фаа2',
      age: 23,
      priceHotSubscription: 5000,
      city: {
        id: 3,
        countryId: 2,
        country: {
          id: 2,
          name: {
            ru: 'США',
            en: 'USA',
            de: '',
          },
        },
        name: {
          ru: 'Нью Йорк',
          en: 'NY',
          de: '',
        },
      },
      catalog: {
        id: 4,
        classname: 'silver',
        title: {
          ru: 'Turkey',
          en: 'Turkey',
          de: '',
        },
      },
      avatars: [
        {
          id: 8,
          type: 'avatar',
          file: {
            id: 2,
            preview:
              '768dff29-6f95-4c78-bc95-b9132af7e82e-06448316-f160-4656-a0cc-c92bd765fd38.jpg',
          },
        },
      ],
    },
    price: 5000,
  },
  {
    id: 15,
    type: 'catalog',
    start: '2023-12-11T18:30:03.995Z',
    end: '2024-12-11T18:30:03.995Z',
    autoDebit: true,
    details: {
      id: 1,
      classname: 'dusty_rose',
      priceSubscription: 0,
      lastUpdate: '2023-12-22T11:32:27.181Z',
      countQuestionnaires: 1,
      questionnaires: [
        {
          id: 6,
          avatars: [
            {
              id: 10,
              file: {
                preview:
                  'dd25a9ad-34db-44b1-a116-7c0c2777a43c-e3645f77-4b68-47b4-b4a9-bc146842dcbb.jpg',
              },
            },
          ],
        },
      ],
      title: {
        ru: 'Клуб Первых',
        en: 'Club of First ',
        de: '',
      },
      subtitle: {
        ru: '+ Бонусный доступ во все наши каталоги',
        en: '+ Bonus access to all our catalogs',
        de: '',
      },
    },
    price: 0,
  },
  {
    id: 12,
    type: 'catalog',
    start: '2023-12-11T18:30:03.995Z',
    end: '2024-12-11T18:30:03.995Z',
    autoDebit: true,
    details: {
      id: 1,
      classname: 'dusty_rose',
      priceSubscription: 0,
      lastUpdate: '2023-12-22T11:32:27.181Z',
      countQuestionnaires: 1,
      questionnaires: [
        {
          id: 6,
          avatars: [
            {
              id: 10,
              file: {
                preview:
                  'dd25a9ad-34db-44b1-a116-7c0c2777a43c-e3645f77-4b68-47b4-b4a9-bc146842dcbb.jpg',
              },
            },
          ],
        },
      ],
      title: {
        ru: 'Клуб Первых',
        en: 'Club of First ',
        de: '',
      },
      subtitle: {
        ru: '+ Бонусный доступ во все наши каталоги',
        en: '+ Bonus access to all our catalogs',
        de: '',
      },
    },
    price: 0,
  },
  {
    id: 11,
    type: 'catalog',
    start: '2023-12-11T18:30:03.995Z',
    end: '2024-12-11T18:30:03.995Z',
    autoDebit: true,
    details: {
      id: 1,
      classname: 'dusty_rose',
      priceSubscription: 0,
      lastUpdate: '2023-12-22T11:32:27.181Z',
      countQuestionnaires: 1,
      questionnaires: [
        {
          id: 6,
          avatars: [
            {
              id: 10,
              file: {
                preview:
                  'dd25a9ad-34db-44b1-a116-7c0c2777a43c-e3645f77-4b68-47b4-b4a9-bc146842dcbb.jpg',
              },
            },
          ],
        },
      ],
      title: {
        ru: 'Клуб Первых',
        en: 'Club of First ',
        de: '',
      },
      subtitle: {
        ru: '+ Бонусный доступ во все наши каталоги',
        en: '+ Bonus access to all our catalogs',
        de: '',
      },
    },
    price: 0,
  },
  {
    id: 10,
    type: 'catalog',
    start: '2023-12-11T18:30:03.995Z',
    end: '2024-12-11T18:30:03.995Z',
    autoDebit: true,
    details: {
      id: 1,
      classname: 'dusty_rose',
      priceSubscription: 0,
      lastUpdate: '2023-12-22T11:32:27.181Z',
      countQuestionnaires: 1,
      questionnaires: [
        {
          id: 6,
          avatars: [
            {
              id: 10,
              file: {
                preview:
                  'dd25a9ad-34db-44b1-a116-7c0c2777a43c-e3645f77-4b68-47b4-b4a9-bc146842dcbb.jpg',
              },
            },
          ],
        },
      ],
      title: {
        ru: 'Клуб Первых',
        en: 'Club of First ',
        de: '',
      },
      subtitle: {
        ru: '+ Бонусный доступ во все наши каталоги',
        en: '+ Bonus access to all our catalogs',
        de: '',
      },
    },
    price: 0,
  },
  {
    id: 9,
    type: 'catalog',
    start: '2023-12-11T18:30:03.995Z',
    end: '2024-12-11T18:30:03.995Z',
    autoDebit: true,
    details: {
      id: 1,
      classname: 'dusty_rose',
      priceSubscription: 0,
      lastUpdate: '2023-12-22T11:32:27.181Z',
      countQuestionnaires: 1,
      questionnaires: [
        {
          id: 6,
          avatars: [
            {
              id: 10,
              file: {
                preview:
                  'dd25a9ad-34db-44b1-a116-7c0c2777a43c-e3645f77-4b68-47b4-b4a9-bc146842dcbb.jpg',
              },
            },
          ],
        },
      ],
      title: {
        ru: 'Клуб Первых',
        en: 'Club of First ',
        de: '',
      },
      subtitle: {
        ru: '+ Бонусный доступ во все наши каталоги',
        en: '+ Bonus access to all our catalogs',
        de: '',
      },
    },
    price: 0,
  },
  {
    id: 1,
    type: 'catalog',
    start: '2023-12-11T18:30:03.995Z',
    end: '2024-12-11T18:30:03.995Z',
    autoDebit: true,
    details: {
      id: 1,
      classname: 'dusty_rose',
      priceSubscription: 0,
      lastUpdate: '2023-12-22T11:32:27.181Z',
      countQuestionnaires: 1,
      questionnaires: [
        {
          id: 6,
          avatars: [
            {
              id: 10,
              file: {
                preview:
                  'dd25a9ad-34db-44b1-a116-7c0c2777a43c-e3645f77-4b68-47b4-b4a9-bc146842dcbb.jpg',
              },
            },
          ],
        },
      ],
      title: {
        ru: 'Клуб Первых',
        en: 'Club of First ',
        de: '',
      },
      subtitle: {
        ru: '+ Бонусный доступ во все наши каталоги',
        en: '+ Bonus access to all our catalogs',
        de: '',
      },
    },
    price: 0,
  },
  {
    id: 13,
    type: 'questionnaire-hot',
    start: '2023-12-11T18:30:03.995Z',
    end: '2024-12-11T18:30:03.995Z',
    autoDebit: true,
    details: {
      id: 6,
      firstName: 'Фаа2',
      age: 23,
      priceHotSubscription: 5000,
      city: {
        id: 2,
        countryId: 1,
        country: {
          id: 1,
          name: {
            ru: 'Россия',
            en: 'Russia',
            de: '',
          },
        },
        name: {
          ru: 'Краснодар',
          en: 'Krasnodar',
          de: '',
        },
      },
      catalog: {
        id: 1,
        classname: 'dusty_rose',
        title: {
          ru: 'Клуб Первых',
          en: 'Club of First ',
          de: '',
        },
      },
      avatars: [
        {
          id: 10,
          type: 'avatar',
          file: {
            id: 3,
            preview:
              'dd25a9ad-34db-44b1-a116-7c0c2777a43c-e3645f77-4b68-47b4-b4a9-bc146842dcbb.jpg',
          },
        },
      ],
    },
    price: 5000,
  },
  {
    id: 14,
    type: 'catalog',
    start: '2023-12-11T18:30:03.995Z',
    end: '2024-12-11T18:30:03.995Z',
    autoDebit: true,
    details: {
      id: 7,
      classname: 'purple',
      priceSubscription: 0,
      lastUpdate: '2023-12-22T11:32:27.181Z',
      countQuestionnaires: 1,
      questionnaires: [
        {
          id: 9,
          avatars: [
            {
              id: 13,
              file: {
                preview:
                  '768dff29-6f95-4c78-bc95-b9132af7e82e-06448316-f160-4656-a0cc-c92bd765fd38.jpg',
              },
            },
          ],
        },
      ],
      title: {
        ru: 'Продажа невинности',
        en: 'Sale of Virginity',
        de: '',
      },
      subtitle: {
        ru: 'Право первой ночи - купить невинность.',
        en: 'Right of the First Night - Purchase Virginity.',
        de: '',
      },
    },
    price: 0,
  },
  {
    id: 8,
    type: 'catalog',
    start: '2023-12-11T18:30:03.995Z',
    end: '2024-12-11T18:30:03.995Z',
    autoDebit: true,
    details: {
      id: 7,
      classname: 'purple',
      priceSubscription: 0,
      lastUpdate: '2023-12-22T11:32:27.181Z',
      countQuestionnaires: 1,
      questionnaires: [
        {
          id: 9,
          avatars: [
            {
              id: 13,
              file: {
                preview:
                  '768dff29-6f95-4c78-bc95-b9132af7e82e-06448316-f160-4656-a0cc-c92bd765fd38.jpg',
              },
            },
          ],
        },
      ],
      title: {
        ru: 'Продажа невинности',
        en: 'Sale of Virginity',
        de: '',
      },
      subtitle: {
        ru: 'Право первой ночи - купить невинность.',
        en: 'Right of the First Night - Purchase Virginity.',
        de: '',
      },
    },
    price: 0,
  },
  {
    id: 7,
    type: 'catalog',
    start: '2023-12-11T18:30:03.995Z',
    end: '2024-12-11T18:30:03.995Z',
    autoDebit: true,
    details: {
      id: 7,
      classname: 'purple',
      priceSubscription: 0,
      lastUpdate: '2023-12-22T11:32:27.181Z',
      countQuestionnaires: 1,
      questionnaires: [
        {
          id: 9,
          avatars: [
            {
              id: 13,
              file: {
                preview:
                  '768dff29-6f95-4c78-bc95-b9132af7e82e-06448316-f160-4656-a0cc-c92bd765fd38.jpg',
              },
            },
          ],
        },
      ],
      title: {
        ru: 'Продажа невинности',
        en: 'Sale of Virginity',
        de: '',
      },
      subtitle: {
        ru: 'Право первой ночи - купить невинность.',
        en: 'Right of the First Night - Purchase Virginity.',
        de: '',
      },
    },
    price: 0,
  },
  {
    id: 3,
    type: 'catalog',
    start: '2023-12-11T18:30:03.995Z',
    end: '2024-12-11T18:30:03.995Z',
    autoDebit: true,
    details: {
      id: 7,
      classname: 'purple',
      priceSubscription: 0,
      lastUpdate: '2023-12-22T11:32:27.181Z',
      countQuestionnaires: 1,
      questionnaires: [
        {
          id: 9,
          avatars: [
            {
              id: 13,
              file: {
                preview:
                  '768dff29-6f95-4c78-bc95-b9132af7e82e-06448316-f160-4656-a0cc-c92bd765fd38.jpg',
              },
            },
          ],
        },
      ],
      title: {
        ru: 'Продажа невинности',
        en: 'Sale of Virginity',
        de: '',
      },
      subtitle: {
        ru: 'Право первой ночи - купить невинность.',
        en: 'Right of the First Night - Purchase Virginity.',
        de: '',
      },
    },
    price: 0,
  },
];
export const SubscriptionsWidget = () => {
  // const { subscriptions } = useAppSelector((state) => state.subscriptions);
  const [modalInfo, setModalInfo] = useState<Omit<CancelSubscriptionModalT, 'close'>>({
    show: false,
    name: '',
    validUntil: '',
    subscriptionId: 0,
  });
  return (
    <div className={styles.widget}>
      {subscriptions.map((s, i) => {
        return (
          <div key={s.id} className={styles.block} style={{ animationDelay: `${0.2 * i}s` }}>
            {generateSubscriptionCard(s)}
            <SubscriptionControl
              subscriptionId={s.id}
              autoDebit={s.autoDebit}
              price={s.price}
              type={s.type}
              subscriptionEnd={s.end}
              setModalInfo={setModalInfo}
            />
          </div>
        );
      })}
      <CancelSubscriptionModal
        close={() => setModalInfo((prev) => ({ ...prev, show: false }))}
        name={modalInfo.name}
        validUntil={modalInfo.validUntil}
        show={modalInfo.show}
        subscriptionId={modalInfo.subscriptionId}
      />
    </div>
  );
};
