import { Tabs } from '../../shared/ui/Tabs';
import { AllUsers } from './Tabs/AllUsers';
import { UserBanner } from '../../widgets/Users/UserBanner';

const tabs = [
  {
    content: <AllUsers />,
    label: 'Все',
    id: 0,
  },
  {
    content: <p>контент</p>,
    label: 'Заблокированные',
    id: 1,
  },
];

const Users = () => {
  return (
    <div style={{ width: '100%' }}>
      <UserBanner />
      <Tabs tabs={tabs} />
    </div>
  );
};

export default Users;
