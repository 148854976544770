import { useNavigate } from 'react-router-dom';
import { CalendarCustom } from 'shared/ui/Calendar';
import { Input } from 'shared/ui/Input';

import styles from './CatalogForm.module.scss';
import { ReactComponent as BackButtonIcon } from '../../../shared/assets/icons/arrowsquareleft.svg';
import { GradientSelect } from '../GradientSelect/GradientSelect';
import { CatalogFormPropsI } from './CatalogForm.props';

export const CatalogForm = ({ handleChangeForm, form }: CatalogFormPropsI) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <button onClick={() => navigate(-1)} className={styles.back_button}>
        <BackButtonIcon />
      </button>
      <div className={styles.content}>
        {<p className={styles.title}>{'Создание каталога'}</p>}
        <ul className={styles.inputs_list}>
          <li className={`${styles.inouts_item} ${styles.first_row}`}>
            <Input
              type={2}
              value={form['title'].value || ''}
              tip={form['title'].placeholder}
              tipAsPlaceholder={true}
              setValue={(val) => handleChangeForm('title', val)}
            />
            {form['title'].errorText && (
              <span className={styles.valid_error}>{form['title'].errorText}</span>
            )}
            <Input
              type={2}
              value={form['subtitle'].value || ''}
              tip={form['subtitle'].placeholder}
              tipAsPlaceholder={true}
              setValue={(val) => handleChangeForm('subtitle', val)}
            />
            {form['subtitle'].errorText && (
              <span className={styles.valid_error}>{form['subtitle'].errorText}</span>
            )}
          </li>
          <li className={`${styles.inouts_item} ${styles.second_row}`}>
            <Input
              type={2}
              value={form['price'].value || ''}
              tip={form['price'].placeholder}
              tipAsPlaceholder={true}
              setValue={(val) => handleChangeForm('price', val)}
            />
            {form['price'].errorText && (
              <span className={styles.valid_error}>{form['price'].errorText}</span>
            )}
            <GradientSelect onClickItem={(id: number) => handleChangeForm('gradient', id)} />
            <CalendarCustom
              title={form['startDate'].placeholder}
              date={form['startDate'].value}
              setDate={(date: any) => handleChangeForm('startDate', date)}
            />
          </li>
        </ul>
        <textarea
          key={form['description']?.id}
          onChange={(event) => handleChangeForm('description', event.target.value)}
          placeholder={form['description']?.placeholder}
          className={styles.textarea}
        />
      </div>
    </div>
  );
};
