import StarSVG from 'shared/assets/ui/chatItem/star2.svg';
import { useState } from 'react';
import { DonateMessageT } from 'entities/chats/types';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';

import styles from './DonateMessage.module.scss';
export const DonateMessage = ({ id }: Partial<DonateMessageT>) => {
  const [donation, setDonation] = useState('');

  return (
    <div className={styles['donate-block']}>
      <p className={styles['title']}>
        <StarSVG />
        {'Спасибо за Вашу оценку!'}
      </p>
      <p className={styles['desc']}>{'Вы можете отблагодарить наш сервис донатом:'}</p>
      <div className={styles['btn-container']}>
        <Button additionalClasses={styles.btn} type={4} onClick={() => setDonation('20')}>
          20 €
        </Button>
        <Button additionalClasses={styles.btn} type={4} onClick={() => setDonation('50')}>
          50 €
        </Button>
        <Button additionalClasses={styles.btn} type={4} onClick={() => setDonation('100')}>
          100 €
        </Button>
      </div>
      <Input
        additionalClasses={styles.input}
        setValue={(val) => setDonation(val)}
        type={2}
        value={donation}
        valueType="number"
        placeholder={'Другая сумма...'}
      />
      <Button type={3} additionalClasses={styles['donate-btn']}>
        {'Оплатить'}
      </Button>
    </div>
  );
};
