import ReactDOM from 'react-dom';
import { ReactComponent as CloseIcon } from 'shared/assets/icons/closesquare.svg';
import { useEffect, useRef, useState } from 'react';
import { useKeyDownOutside } from 'shared/hooks/useKeyDownOutside/useKeyDownOutside';
import { BUTTONS_ON_KEYBOARD } from 'shared/constans/buttonOnKeyboard/buttonOnKeyboard';

import styles from './modal.module.scss';
import { ModalPropsI } from './Modal.props';
export const Modal = ({ children, isVisible, close, additionalClasses = '' }: ModalPropsI) => {
  const [isDocument, setIsDocument] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  useKeyDownOutside(modalRef, close, isVisible, BUTTONS_ON_KEYBOARD.Escape);

  useEffect(() => {
    if (isVisible) {
      setIsDocument(true);
    } else {
      setIsDocument(false);
    }
  }, [isVisible]);

  return isVisible
    ? ReactDOM.createPortal(
        <div
          ref={modalRef}
          className={`${styles.wrapper} ${isDocument ? styles.show : styles.hide}`}
          onClick={close}
        >
          <div
            className={`${styles.modal}  ${additionalClasses}`}
            onClick={(e) => e.stopPropagation()}
          >
            {children}
            <CloseIcon className={styles.close} onClick={close} />
          </div>
        </div>,
        document.body
      )
    : null;
};
