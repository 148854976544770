import PhoneSVG from 'shared/assets/ui/chatItem/phone.svg';
import { convertISODateToTime } from 'shared/helpers/dateFunctions';

import styles from './ContactRequestMessage.module.scss';

export const ContactRequestMessage = ({ time }: { time: string }) => {
  return (
    <div className={styles.block}>
      <div className={styles['svg-block']}>
        <PhoneSVG />
      </div>
      <div className={styles.info}>
        <div className={styles.desc}>{'Запрос контактов'}</div>
        <div className={styles.time}>{convertISODateToTime(time)}</div>
      </div>
    </div>
  );
};
