import { BonusesListItem } from '../BonusesListItem';
import { BonusesStatus } from '../BonusesListItem/BonusesListItem.props';
import styles from './BonusesList.module.scss';

const data = [
  { id: 1, name: 'Бонус +200$', blockSum: 0, status: 'received' },
  { id: 2, name: 'Бонус +200$', blockSum: 0, status: 'unreceived' },
  { id: 3, name: 'Бонус Каталог PLATINUM', blockSum: 2800, status: 'blocked' },
];

export const BonusesList = () => {
  return (
    <ul className={styles.list}>
      {data.map(({ id, name, blockSum, status }) => (
        <BonusesListItem
          id={id}
          key={id}
          blockSum={blockSum}
          status={status as BonusesStatus}
          name={name}
        />
      ))}
    </ul>
  );
};
