import { BlockUserBtn } from 'features/user/BlockUserBtn';
import { Input } from 'shared/ui/Input';

import styles from './UserStatusWidget.module.scss';
export const UserStatusWidget = () => {
  return (
    <div className={`${styles.widget} block`}>
      <div className={styles.info}>
        <div className={styles.register}>Дата регистрации: 15.06.2023</div>
        <BlockUserBtn />
      </div>
      <div className={styles['inputs-block']}>
        <Input type={2} value={'JacTapok'} tip="Ник" />
        <Input type={2} value={'Новичок (#560)'} tip="Статус" />
      </div>
    </div>
  );
};
