import styles from './FileList.module.scss';
import { FileListPropsI } from './FileList.props';
import { PhotoListItem } from './PhotoListItem';
import { VideoListItem } from './VideoListItem';

export const FileList = ({ imagesArray, handleRemove }: FileListPropsI) => {
  return imagesArray.length ? (
    <ul className={styles.list}>
      {imagesArray.map(({ full, type, id }) =>
        type === 'image' ? (
          <PhotoListItem key={id} preview={full} handleRemove={handleRemove} />
        ) : (
          <VideoListItem key={id} />
        )
      )}
    </ul>
  ) : null;
};
