import dayjs from 'dayjs';

export const convertISODateToTime = (ISODate: string) => {
  return new Date(ISODate).toLocaleTimeString().slice(0, -3);
};

export const getFormatedDate = (date: string) => {
  return new Date(date).toLocaleDateString();
};

export const calculateRemainingTimePercents = (startDate: Date, endDate: Date) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const totalDuration = end.diff(start);
  const elapsedDuration = dayjs().diff(start);
  const percentPassed = (elapsedDuration / totalDuration) * 100;
  const remainingPercents = 100 - percentPassed;
  return remainingPercents.toFixed(2);
};
