import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';

import { Loader } from '../../ui/Loader';

const PrivateRoute = ({ redirectPath = '/login' }) => {
  const { isAuthenticated, authLoading } = useSelector((state: any) => state.auth);

  if (authLoading) {
    return <Loader />;
  }
  if (!isAuthenticated) {
    return <Navigate to={redirectPath} />;
  }
  return <Outlet />;
};

export default PrivateRoute;
