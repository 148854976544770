import { convertISODateToTime } from 'shared/helpers/dateFunctions';
import { WithrawFromBalanceBtn } from 'features/chat/WithdrawFromBalanceBtn';
import { ChatMoneyT } from 'entities/chats/types';
import PurseSVG from 'shared/assets/icons/purse.svg';

import styles from './ChatMoney.module.scss';

export const ChatMoney = (props: ChatMoneyT) => {
  return (
    <div className={`${styles['money-block']} ${styles[props.from]}`}>
      <div className="flex">
        <div className={styles['svg-block']}>
          <PurseSVG />
        </div>
        <div>
          <div className={styles.info}>
            <div className={styles.amount}>{props.amount.toLocaleString('ru-RU')}€</div>
            <div className={styles.time} suppressHydrationWarning>
              {convertISODateToTime(props.time)}
            </div>
          </div>
          <div className={styles[props.status]}>
            {props.status === 'request' ? 'Запрос оплаты' : 'Оплачено'}
          </div>
        </div>
      </div>

      {props.status === 'request' && <WithrawFromBalanceBtn />}
    </div>
  );
};
