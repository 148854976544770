import { ReactComponent as TrashSVG } from 'shared/assets/icons/trash.svg';

import styles from './ImagesContainer.module.scss';

export const ImagesContainer = () => {
  const images = [{ id: 1 }, { id: 2 }];
  return (
    <ul className={styles.list}>
      {images.map(({ id }) => (
        <li key={id} className={styles.item}>
          <button className={styles.trash}>
            <TrashSVG />
          </button>
        </li>
      ))}
    </ul>
  );
};
