import styles from './Сell.module.scss';
import { CellProps } from './Сell.props';

export const Сell = ({ text, value }: CellProps) => {
  return (
    <div className={styles.container}>
      <p className={styles.text}>{text}</p>
      <p className={styles.value}>{value}</p>
    </div>
  );
};
