import { IMAGES_URL } from 'shared/config/urls';

import { ImagesListPropsI } from './ImageList.props';
import styles from './ImageList.module.scss';

export const ImagesList = ({ images, currentImageIndex, width, height }: ImagesListPropsI) => {
  return (
    <ul style={{ gridTemplateColumns: `repeat(${images.length}, 100%)` }} className={styles.list}>
      {images.map(({ id, file }) => (
        <li
          className={styles.item}
          style={{ transform: `translateX(calc(-${currentImageIndex} * (100%)))` }}
          key={id}
        >
          {typeof file === 'object' ? (
            <img
              className={styles.image}
              height={height || 200}
              width={width || 200}
              src={`${IMAGES_URL}${file?.preview || file?.previewBlur}`}
              alt="test"
            />
          ) : (
            <img
              className={styles.image}
              height={height || 200}
              width={width || 200}
              src={`${IMAGES_URL}${file}`}
              alt="test"
            />
          )}
        </li>
      ))}
    </ul>
  );
};
