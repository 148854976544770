import { ChatRoomT, ChatT } from 'entities/chats/types';
import classNames from 'classnames';

import styles from './ChatBox.module.scss';
import { ChatStatusBar } from './Components';
import { ChatWindow } from './Components/ChatWindow';
import { EnterMessageBlock } from './Components/EnterMessageBlock';
export const ChatBox = ({
  chat,
  status,
  name,
}: {
  chat: ChatT | undefined;
  status: ChatRoomT['status'];
  name: string | null;
}) => {
  return (
    <div className={classNames(styles.box, !chat && [styles['select-chat']])}>
      <ChatStatusBar status={status} />
      <ChatWindow chat={chat} name={name} />
      {chat && <EnterMessageBlock chatRoomId={chat.chatRoomId} />}
    </div>
  );
};
