import { configureStore } from '@reduxjs/toolkit';
import { catalogApi } from 'entities/catalogs/api';

import catalogsReducer from '../../entities/catalogs/model';
import questionnairesReducer from '../../entities/questionnaires/models';
import auctionsReducer from '../../entities/auctions/model';
import authReducer from '../../features/auth/authSlice';

export const store = configureStore({
  ///@ts-ignore
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), catalogApi.middleware],
  reducer: {
    catalogs: catalogsReducer,
    questionnaires: questionnairesReducer,
    auctions: auctionsReducer,
    auth: authReducer,
  },
});

export type AppStore = typeof store;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
