import { convertISODateToTime } from 'shared/helpers/dateFunctions';
import { ChatMessageT } from 'entities/chats/types';
import classNames from 'classnames';

import styles from './ChatMessage.module.scss';

export const ChatMessage = (props: ChatMessageT & { lastMsg: boolean }) => {
  return (
    <div
      className={classNames(
        `${styles['message-box']} ${styles[props.from]}`,
        props.lastMsg && [styles.last]
      )}
    >
      <div className={styles.message}>{props.message}</div>
      <div className={styles.time} suppressHydrationWarning>
        {convertISODateToTime(props.time)}
      </div>
    </div>
  );
};
