import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from 'shared/config/api/api';

import { QuestionnairesResponse } from '../types';

export const getAllQuestionnaires = createAsyncThunk(
  'questionnaires/all',
  async (qParamsParsedString: string) => {
    const response = await axios.get<QuestionnairesResponse>(
      `${BASE_URL}/questionnaires${qParamsParsedString}`
    );

    return response.data.questionnaires;
  }
);
