import { useAppDispatch, useAppSelector } from 'app/store';
import { useEffect } from 'react';
import { getAllAuctions } from 'entities/auctions/api';

import { AuctionsList } from '../../../../widgets/Auctions/AuctionsList';

export const ClosedAuctions = () => {
  // const auctions = [
  //   {
  //     price: 6500,
  //     isNew: true,
  //     startSum: 2000,
  //     quantity: 15,
  //     status: 'default',
  //     id: 0,
  //     isClosed: false,
  //     isWinning: false,
  //     members: 15,
  //   },
  //   {
  //     price: 13000,
  //     isNew: false,
  //     startSum: 500,
  //     quantity: 2050,
  //     status: 'default',
  //     id: 1,
  //     isClosed: false,
  //     isWinning: false,
  //     members: 15,
  //   },
  //   {
  //     price: 6500,
  //     isNew: false,
  //     startSum: 500,
  //     quantity: 2050,
  //     status: 'default',
  //     id: 2,
  //     isClosed: false,
  //     isWinning: false,
  //     members: 15,
  //   },
  // ];

  const { auctions } = useAppSelector((state) => state.auctions);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllAuctions(true));
  }, []);
  return <AuctionsList auctions={auctions} isAuctionPage={true} isClosedTab={true} />;
};
