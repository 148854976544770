import { CatalogItem } from 'entities/catalogs/ui/CatalogItem/CatalogItem';
import { ReactComponent as AddIcon } from 'shared/assets/icons/addsquareyellow.svg';

import styles from './CatalogsList.module.scss';
import { CatalogListProps } from './CatalogsList.props';

export const CatalogsList = ({ catalogList, addCatalogButton }: CatalogListProps) => {
  const BASE_ANIMATION_DELAY = 0.2;
  const animationDelay = catalogList.length
    ? catalogList[catalogList.length - 1].id * BASE_ANIMATION_DELAY
    : 0;
  return (
    <div className={styles.list}>
      {catalogList.map(
        (
          {
            title,
            subtitle,
            id,
            isBuyed,
            price,
            isFavorite,
            questionnaires,
            countQuestionnaires,
            classname,
            lastUpdate,
          },
          index
        ) => (
          <CatalogItem
            key={id}
            index={index}
            countQuestionnaires={countQuestionnaires}
            classname={classname}
            title={title}
            subtitle={subtitle}
            id={id}
            price={price}
            isBuyed={isBuyed}
            isFavorite={isFavorite}
            questionnaires={questionnaires}
            lastUpdate={lastUpdate}
          />
        )
      )}
      {addCatalogButton ? (
        <div style={{ animationDelay: `${animationDelay}s` }} className={styles.item}>
          <button className={styles.buy_catalog_button}>
            <AddIcon />
            Докупить каталог
          </button>
        </div>
      ) : null}
    </div>
  );
};
