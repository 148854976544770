import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import styles from './QuickNavigation.module.scss';
import { DetailsNavLink } from './constans';

export const QuickNavigation = () => {
  const { pathname } = useLocation();

  return (
    <nav className={styles.nav}>
      <ul className={styles.list}>
        {DetailsNavLink.map(({ id, link, Icon, badge }) => (
          <li className={styles.item} key={id}>
            {badge && <span className={styles.badge}>{badge}</span>}
            <Link
              className={classNames(
                styles.item_link,
                pathname.includes(link) && styles.active_item_link
              )}
              to={link}
            >
              {<Icon />}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};
