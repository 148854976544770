import { DropDownButtons } from 'shared/ui/DropDownButtons/DropDownButtons';
import { useDropDownResize } from 'shared/hooks/useDropDownResize';
import { ReactComponent as MoreSVG } from 'shared/assets/icons/more.svg';
import { ReactComponent as ArrowIcon } from 'shared/assets/icons/arrow_chat.svg';

import styles from './HeaderCurrentUserDialog.module.scss';
import { HeaderCurrentUserDialogProps } from './HeaderCurrentUserDialog.props';

export const HeaderCurrentUserDialog = ({
  name,
  dialogs,
  setCurrentUserDialogs,
}: HeaderCurrentUserDialogProps) => {
  const { setIsVisible, isVisible, parentalDropdownRef, positions, setPositions } =
    useDropDownResize();
  const buttons = [
    { id: 1, name: 'Профиль', onClick: () => {} },
    { id: 2, name: 'Заблокировать', onClick: () => {} },
    { id: 3, name: 'Удалить этот чат', onClick: () => {} },
  ];
  return (
    <div className={styles.header}>
      <button className={styles.exit} onClick={() => setCurrentUserDialogs(null)}>
        <ArrowIcon />
      </button>
      <div className={styles.info}>
        <p className={styles.name}>{name}</p>
        <p className={styles.dialogs}>{dialogs} диалога</p>
      </div>
      <button
        className={styles.button}
        ref={parentalDropdownRef}
        onClick={() => setIsVisible(true)}
      >
        <MoreSVG />
      </button>
      <DropDownButtons
        buttons={buttons}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        rectValue={positions}
        setPositions={setPositions}
      />
    </div>
  );
};
