import { Button } from 'shared/ui/Button';
import { DeletePhotoButton } from 'features/questionnaire/DeletePhotoButton';
import { UploadModal } from 'widgets/UploadModal';
import { useState } from 'react';

import mockPhoto from '../../../shared/assets/mock/mock3.png';
import styles from './Video.module.scss';
import { ReactComponent as AddIcon } from '../../../shared/assets/icons/addsquare.svg';

export const Video = () => {
  const [isVisibleAddPhotoModal, setIsVisibleAddPhotoModal] = useState(false);
  const FakeData = [
    {
      img: mockPhoto,
      id: 0,
    },
    {
      img: mockPhoto,
      id: 1,
    },
    {
      img: mockPhoto,
      id: 2,
    },
    {
      img: mockPhoto,
      id: 3,
    },
  ];
  return (
    <ul className={styles.list}>
      <li className={styles.item}>
        <Button onClick={() => setIsVisibleAddPhotoModal(true)} type={6}>
          <AddIcon />
          Добавить
        </Button>
      </li>
      {FakeData.map(({ img, id }) => (
        <li className={styles.item} key={id}>
          <img className={styles.image} src={img} alt="photos" />
          <div className={styles.delete}>
            <DeletePhotoButton />
          </div>
        </li>
      ))}
      <UploadModal
        isVisible={isVisibleAddPhotoModal}
        close={() => setIsVisibleAddPhotoModal(false)}
        handleSetFiles={() => {}}
        dropzoneText="Загрузите фото с устройства или перетащите файлы в это поле"
        title="Добавить новое фото"
      />
    </ul>
  );
};
