import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { CatalogRowProps } from './CatalogRow.props';
import styles from './CatalogRow.module.scss';
import { catalog, fecthChatIds, telegramBotImages } from '../../../shared/config/api/api';
import { ReactComponent as TickIcon } from '../../../shared/assets/icons/tick.svg';
import { IdsList } from '../IdsList';
import { processImage } from '../../../shared/helpers/proccesImage';

export const CatalogRow = ({
  title,
  price,
  id,
  token,
  chatIds,
  preOrder,
  setChatIds,
  telegramBotImagesArray,
}: CatalogRowProps) => {
  const [newPrice, setNewPrice] = useState('');
  const [isVisibleSaveText, setIsVisibleSaveText] = useState(false);
  const [isLocalPreOred, setIsLocalPreorder] = useState(preOrder);
  const [image, setImage] = useState<string>('');

  const filePicker = useRef<HTMLInputElement | null>(null);

  const handlePick = () => {
    if (filePicker.current) {
      filePicker.current.click();
    }
  };

  const currentImageCatalog = telegramBotImagesArray.find(({ typeId }: any) => typeId === id);

  const handleUploadImage = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      try {
        const croppedImage = await processImage(file);
        setImage(croppedImage);
        telegramBotImages.changeCurrentTelegramBotImage(
          currentImageCatalog?.id,
          croppedImage,
          token
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [inputs, setInputs] = useState<string[]>([]);

  const handleClickAddNewInput = async () => {
    try {
      await catalog.createChatId(token, id).then(() => fecthChatIds(setChatIds, token));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const matchingElements = chatIds.filter(({ catalogId }: any) => catalogId === id);
    const mappedInputs = matchingElements.map(({ id, telegramChatId }: any) => ({
      id,
      telegramChatId,
    }));
    setInputs(mappedInputs);
  }, [chatIds, id]);

  const handleDeleteIds = async (ids: number, telegramChatId: string, id: number) => {
    try {
      await catalog.deleteChatId(id, token);
      setInputs((prev: string[]) => prev.filter((item: any, index) => item.id !== id));
    } catch (error) {
      console.log(error);
    }
  };

  const convertNewPrice = Number(newPrice) * 100;

  const handleChangePrice = async () => {
    try {
      await catalog.changeCatalogsPrice(id, convertNewPrice, token);
      setIsVisibleSaveText(true);
      setTimeout(() => {
        setIsVisibleSaveText(false);
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickPreOrderButton = async () => {
    try {
      if (!isLocalPreOred) {
        setIsLocalPreorder(true);
        await catalog.changePreOrder(token, true, id);
      } else {
        setIsLocalPreorder(false);
        await catalog.changePreOrder(token, false, id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeInputValue = (event: ChangeEvent<HTMLInputElement>) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, '');
    setNewPrice(numericValue);
  };

  return (
    <li className={styles.row}>
      <span>
        <p className={styles.title}>{title}</p>
      </span>
      <p className={styles.price}>{price}</p>
      <input
        onChange={handleChangeInputValue}
        className={styles.input}
        type="text"
        placeholder="новая сумма"
        value={newPrice}
      />
      <ul className={styles.listIds}>
        {inputs.map(({ telegramChatId, id }: any, index: number) => (
          <IdsList
            key={id}
            index={index}
            telegramChatId={telegramChatId}
            id={id}
            handleDeleteIds={handleDeleteIds}
            token={token}
          />
        ))}
      </ul>
      {inputs.length ? (
        <button onClick={handleClickAddNewInput} className={styles.add_ids_button}>
          +
        </button>
      ) : (
        ''
      )}
      <div className={styles.image_container}>
        <img className={styles.image} src={image || currentImageCatalog?.base64} alt="" />
        <button onClick={handlePick} className={styles.add_new_ids_button}>
          изменить
        </button>
        <input
          ref={filePicker}
          onChange={handleUploadImage}
          type="file"
          className={styles.hidden}
        />
      </div>
      <button
        onClick={handleClickPreOrderButton}
        style={{ width: 40, height: 34 }}
        className={styles.add_ids_button}
      >
        {isLocalPreOred ? <TickIcon /> : ''}
      </button>
      <div className={styles.save_container}>
        <button
          disabled={!newPrice || !token}
          onClick={handleChangePrice}
          className={styles.save_button}
        >
          Сохранить
        </button>
        {isVisibleSaveText && <span className={styles.save_text}>Сохранено</span>}
      </div>
    </li>
  );
};
