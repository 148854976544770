import { useRef } from 'react';
import { ContactMessageT } from 'entities/chats/types';
import { ReactComponent as Copy } from 'shared/assets/pages/profile/referals/copy.svg';
import StarSVG from 'shared/assets/ui/chatItem/star2.svg';

import styles from './ContactMessage.module.scss';
export const ContactMessage = ({ firstName, age, number, hidden }: Partial<ContactMessageT>) => {
  const numberBlockRef = useRef<HTMLDivElement>(null);
  const copyRef = () => {
    if (numberBlockRef.current && navigator) {
      navigator.clipboard.writeText(numberBlockRef.current.innerText);
    }
  };
  return (
    <div className={styles.box}>
      <p className={styles.title}>
        <StarSVG /> <span>{`${firstName}, ${age}`}</span>
      </p>
      <p className={styles.desc}>{'Контакты девушки будут скрыты в течение 24 часов'}</p>

      {!hidden && (
        <div className={styles.phone} ref={numberBlockRef}>
          {number} <Copy className={styles.copy} onClick={copyRef} />
        </div>
      )}
    </div>
  );
};
