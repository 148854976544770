import { Сell } from 'shared/ui/Сell';

import { BonusesListItemProps } from './BonusesListItem.props';
import styles from './BonusesListItem.module.scss';

const StatusMap = {
  received: 'Получен',
  unreceived: 'Не получен',
  blocked: 'Заблокирован',
};

export const BonusesListItem = ({ name, status, blockSum }: BonusesListItemProps) => {
  return (
    <li className={styles.item}>
      <p className={styles.name}>{name}</p>
      <Сell value={`${blockSum.toLocaleString('ru-RU')}$`} text="Сумма до разблокировки" />
      <Сell value={StatusMap[status]} />
      <button disabled={status === 'received'} className={styles.button}>
        Удалить бонус и пользователя
      </button>
    </li>
  );
};
