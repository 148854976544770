import ChartSVG from 'shared/assets/ui/chatItem/chart.svg';
import { ReactComponent as GirlSVG } from 'shared/assets/ui/chatItem/girl.svg';
import { useState } from 'react';
import { RatingMessageT } from 'entities/chats/types';

import styles from './RatingMessage.module.scss';
export const RatingMessage = ({ id, rate }: Partial<RatingMessageT>) => {
  const [localRate, setLocalRate] = useState(rate);
  const [hoverRate, setHoverRate] = useState<null | number>(null);
  return (
    <div className={styles['rating-block']}>
      <p className={styles['title']}>
        <ChartSVG /> {'Как все прошло?'}
      </p>
      <p className={styles['desc']}>
        {'Оцените вашу встречу, это поможет развитию нашего сервиса'}
      </p>
      <div
        className={`${styles.girls} ${
          hoverRate
            ? styles[`girls${hoverRate}`]
            : localRate && localRate > 0
              ? styles[`girls${localRate}`]
              : ''
        }`}
      >
        <GirlSVG
          onClick={() => setLocalRate(1)}
          onMouseEnter={() => setHoverRate(1)}
          onMouseLeave={() => setHoverRate(null)}
        />
        <GirlSVG
          onClick={() => setLocalRate(2)}
          onMouseEnter={() => setHoverRate(2)}
          onMouseLeave={() => setHoverRate(null)}
        />
        <GirlSVG
          onClick={() => setLocalRate(3)}
          onMouseEnter={() => setHoverRate(3)}
          onMouseLeave={() => setHoverRate(null)}
        />
        <GirlSVG
          onClick={() => setLocalRate(4)}
          onMouseEnter={() => setHoverRate(4)}
          onMouseLeave={() => setHoverRate(null)}
        />
        <GirlSVG
          onClick={() => setLocalRate(5)}
          onMouseEnter={() => setHoverRate(5)}
          onMouseLeave={() => setHoverRate(null)}
        />
      </div>
    </div>
  );
};
