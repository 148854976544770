import { useAppDispatch, useAppSelector } from 'app/store';
import { fetchAllCatalogs } from 'entities/catalogs/api';
import { useEffect } from 'react';
import { CatalogsList } from 'widgets/Catalogs/CatalogsList';

export const AllCatalogsTab = () => {
  const { catalogs } = useAppSelector((state) => state.catalogs);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllCatalogs());
  }, []);

  return <CatalogsList catalogList={catalogs} />;
};
