import styles from './UserBanner.module.scss';
import { ReactComponent as SearchIcon } from '../../../shared/assets/icons/search-normal.svg';
import { UserBannerProps } from './UserBanner.props';

export const UserBanner = ({ name }: UserBannerProps) => {
  return (
    <div className={styles.banner}>
      <span className={styles.title}>{name || 'Пользователи'}</span>
      <div className={styles.input_container}>
        <input placeholder="Поиск по нику" className={styles.input} type="text" />
        <button>
          <SearchIcon className={styles.search_icon} />
        </button>
      </div>
    </div>
  );
};
