import { ReactComponent as CopyButtonIccon } from 'shared/assets/icons/copy.svg';
import { useRef } from 'react';
import { Button } from 'shared/ui/Button';

import styles from './ReferalsBanner.module.scss';

export const ReferalsBanner = () => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClickCopyButton = async () => {
    if (inputRef.current) {
      try {
        await navigator.clipboard.writeText(inputRef.current.value);
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    }
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>Реферальная ссылка:</p>
      <div className={styles.content}>
        <label className={styles.label} htmlFor="referal_id">
          <input
            onChange={() => {}}
            ref={inputRef}
            className={styles.input}
            id="referal_id"
            type="text"
            value={'JHUH74nfoJkk83hJHK9292HJkljrfgkjdfgkjdkjdfjeirdgjieurgjijr'}
          />
          <button onClick={handleClickCopyButton}>
            <CopyButtonIccon />
          </button>
        </label>
        <Button additionalClasses={styles.button} type={3}>
          Сохранить изменения
        </Button>
      </div>
    </div>
  );
};
