import UserIcon from 'shared/assets/mock/user.png';

import styles from './CurrentMessageItem.module.scss';
import { CurrentMessageItemProps } from './CurrentMessageItem.props';

export const CurrentMessageItem = ({
  name,
  unreadMessage,
  time,
  lastMessage,
}: CurrentMessageItemProps) => {
  return (
    <button className={styles.button}>
      <img src={UserIcon} alt="user_photo" />
      <div className={styles.info}>
        <p className={styles.name}>{name}</p>
        <p className={styles.dialogs}>{lastMessage}</p>
      </div>
      <div className={styles.buttons}>
        <span className={styles.time}>{time}</span>

        {unreadMessage ? <p className={styles.unread_message}>{unreadMessage}</p> : null}
      </div>
    </button>
  );
};
