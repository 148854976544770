import { Button } from 'shared/ui/Button';

import { ReactComponent as TrashIcon } from '../../../shared/assets/icons/trash.svg';

interface DeletePhotoButtonPropsI {
  className?: string;
}

export const DeletePhotoButton = ({ className }: DeletePhotoButtonPropsI) => {
  return (
    <Button type={4}>
      <TrashIcon />
    </Button>
  );
};
