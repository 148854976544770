import { Button } from 'shared/ui/Button';
import { useNavigate } from 'react-router-dom';
import { memo } from 'react';

import styles from './Banner.module.scss';
import { ReactComponent as BackButtonIcon } from '../../../shared/assets/icons/arrowsquareleft.svg';
import { BannerPropsI } from './Banner.props';

export const Banner = memo(({ title, buttonText }: BannerPropsI) => {
  const navigate = useNavigate();
  return (
    <div className={styles.banner}>
      <div className={styles.banner_left_content}>
        <button onClick={() => navigate(-1)}>
          <BackButtonIcon />
        </button>
        <p className={styles.title}>{title}</p>
      </div>
      <Button onClick={() => {}} additionalClasses={styles.button} type={3}>
        <span className={styles.save_button}>{buttonText}</span>
      </Button>
    </div>
  );
});
