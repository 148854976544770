import { Fragment, useEffect, useRef, useState } from 'react';
import { SelectionMessageT } from 'entities/chats/types';
import MarkSVG from 'shared/assets/ui/chatItem/clipboardtick.svg';
import { convertISODateToTime } from 'shared/helpers/dateFunctions';
import { Button } from 'shared/ui/Button';
import classNames from 'classnames';

import styles from './SelectionMessage.module.scss';
export const SelectionMessage = ({ selection }: { selection: SelectionMessageT }) => {
  const fieldsContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const fieldsNode = fieldsContainerRef.current;
    console.log(fieldsNode);

    const scrollHandler = (e: WheelEvent) => {
      e.stopPropagation();
      e.preventDefault();
      const currentTarget = e.currentTarget as HTMLDivElement;
      if (currentTarget) {
        currentTarget.scrollTo({
          top: 0,
          left: currentTarget.scrollLeft + e.deltaY,
          behavior: 'smooth',
        });
      }
    };
    if (fieldsNode) {
      fieldsNode.addEventListener('wheel', scrollHandler);
      return () => {
        fieldsNode.removeEventListener('wheel', scrollHandler);
      };
    }
  }, []);
  const [activeFolder, setActiveFolder] = useState(1);

  const folders: any = [];
  const fields: any = [];
  selection.girls.forEach((g, i) => {
    folders.push(
      <Button
        type={4}
        key={g.key}
        additionalClasses={classNames(
          styles['girl-folder-btn'],
          activeFolder === g.key && [styles.active]
        )}
        onClick={() => setActiveFolder(g.key)}
      >
        <>
          {'Девушка'} #{i + 1}
        </>
      </Button>
    );
    fields.push(
      <div
        key={g.key}
        className={classNames(styles['fields-block'], {
          [styles.active]: activeFolder === g.key,
        })}
      >
        <div className={styles.field}>
          <span className={styles['field-name']}>{'Рост'}</span>
          {g.height}
        </div>
        <div className={styles.field}>
          <span className={styles['field-name']}>{'Грудь'}</span>
          {g.breast}
        </div>
        <div className={styles.field}>
          <span className={styles['field-name']}>{'Вес'}</span>
          {g.weight}
        </div>
        <div className={styles.field}>
          <span className={styles['field-name']}>{'Цвет волос'}</span>
          {g.hairColor}
        </div>
        <div className={styles.field}>
          <span className={styles['field-name']}>{'Типаж'}</span>
          {g.type}
        </div>
        <div className={styles.field}>
          <span className={styles['field-name']}>{'Цель'}</span>
          {g.purpose}
        </div>
        <div className={styles.field}>
          <span className={styles['field-name']}>{'Сфера'}</span>
          {g.sphere}
        </div>
      </div>
    );
  });
  return (
    <Fragment>
      <div className={styles.box}>
        <div className={styles['title-block']}>
          <MarkSVG /> <span className={styles.title}>{'Подбор'}</span>{' '}
          <span className={styles.time}>{convertISODateToTime(selection.time)}</span>
        </div>
        <div
          className={`${styles['folders-block']}  scroll`}
          onWheel={(e) => {
            // e.nativeEvent.stopImmediatePropagation();
            // e.preventDefault();
            // e.stopPropagation();
            // e.currentTarget.scrollTo({
            //   top: 0,
            //   left: e.currentTarget.scrollLeft + e.deltaY,
            //   behavior: 'smooth',
            // });
          }}
          ref={fieldsContainerRef}
        >
          {folders}
        </div>
        {fields}
      </div>
      {selection.comment && (
        <div className={styles['comment-block']}>
          <div className={styles['title-block']}>
            <MarkSVG /> <span className={styles.title}>{'Комментарии и пожелания'}</span>
          </div>
          <p className={styles.comment}>{selection.comment}</p>
        </div>
      )}
    </Fragment>
  );
};
