import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../app/store';
import { login } from '../../features/auth/authSlice';
import styles from './Login.module.scss';
import { Button } from '../../shared/ui/Button';

interface FieldErrors {
  email?: string;
  password?: string;
}

interface ValidationError {
  type: string;
  value: string;
  path: string[];
  message: string;
}

interface AuthError {
  success: boolean;
  message: string;
  errors?: ValidationError[];
  status: number;
}

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});
  const { authLoading, error } = useSelector(
    (state: { auth: { authLoading: boolean; error: AuthError | null } }) => state.auth
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (error && error.status === 422 && error.errors) {
      const validationErrors = error.errors.reduce((acc: FieldErrors, err: ValidationError) => {
        if (err.path[0]) {
          acc[err.path[0] as keyof FieldErrors] = err.message;
        }
        return acc;
      }, {} as FieldErrors);
      setFieldErrors(validationErrors);
    } else {
      setFieldErrors({});
    }
  }, [error]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setFieldErrors({});
    const result = await dispatch(login({ email, password }) as any);
    if (result.meta.requestStatus === 'fulfilled') {
      navigate('/users');
    }
  };

  return (
    <div className={styles.loginContainer}>
      <form className={styles.loginForm} onSubmit={handleLogin}>
        {error && error.status !== 422 && (
          <p className={styles.errorMessage}>Ошибка аутентификации</p>
        )}
        <input
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {fieldErrors.email && <p className={styles.fieldError}>{fieldErrors.email}</p>}
        <input
          type="password"
          placeholder="Пароль"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {fieldErrors.password && <p className={styles.fieldError}>{fieldErrors.password}</p>}
        <Button type={4}>{authLoading ? 'Загрузка...' : 'Войти'}</Button>
      </form>
    </div>
  );
};

export default Login;
