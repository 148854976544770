import { useRef, useState } from 'react';
import { useKeyDownOutside } from 'shared/hooks/useKeyDownOutside/useKeyDownOutside';
import { useOutsideClick } from 'shared/hooks/useOutsideClick/useOutsideClick';
import { BUTTONS_ON_KEYBOARD } from 'shared/constans/buttonOnKeyboard/buttonOnKeyboard';

import { GradientSelectPropsI } from './GradientSelect.props';
import styles from './GradientSelect.module.scss';

export const GradientSelect = ({ onClickItem }: GradientSelectPropsI) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  const [currentColor, setCurrentColor] = useState('');

  useOutsideClick(selectRef, () => setIsOpen(false), isOpen);
  useKeyDownOutside(selectRef, () => setIsOpen(false), isOpen, BUTTONS_ON_KEYBOARD.Enter);

  const options = [
    { color: 'linear-gradient(to right, rgba(185, 166, 153, 1), rgba(119, 108, 104, 1))', id: 1 },
    { color: 'linear-gradient(to right, rgba(185, 166, 153, 1), rgba(123, 110, 10, 1))', id: 2 },
  ];

  const handleClickOpenSelect = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickItem = (id: number, color: string) => {
    onClickItem(id);
    setCurrentColor(color);
    setIsOpen(false);
  };
  return (
    <div ref={selectRef} className={styles.container}>
      <button onClick={handleClickOpenSelect} className={styles.button}>
        <div className={styles.color_block} style={{ backgroundImage: currentColor }}></div>
      </button>
      {isOpen && (
        <ul className={styles.select_list}>
          {options.map(({ color, id }) => (
            <li className={styles.select_item} key={id}>
              <button
                className={styles.item_button}
                onClick={() => handleClickItem(id, color)}
                style={{ backgroundImage: color }}
              ></button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
