import { useCallback, useEffect, useRef, useState } from 'react';

export const useDropDownResize = () => {
  const parentalDropdownRef = useRef<HTMLButtonElement | null>(null);
  const [positions, setPositions] = useState<DOMRect | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  const updatePosition = useCallback(() => {
    if (parentalDropdownRef.current) {
      const rect = parentalDropdownRef.current.getBoundingClientRect();
      setPositions(rect);
    }
  }, []);

  useEffect(() => {
    updatePosition();

    window.addEventListener('resize', updatePosition);

    return () => {
      window.removeEventListener('resize', updatePosition);
    };
  }, [updatePosition, isVisible, positions?.left]);

  return { isVisible, setIsVisible, positions, parentalDropdownRef, setPositions };
};
