import { createAsyncThunk } from '@reduxjs/toolkit';
import { instance } from 'shared/config/api/api';

import { createAuctionRequestDateI } from '../types';
import { responseAuctionsDataI } from '../model/interfaces';

export const getAllAuctions = createAsyncThunk('auctions/all', async (isClosed: boolean) => {
  const { data } = await instance.get<responseAuctionsDataI>(
    `/auctions/get?page=1&limit=50&closed=${isClosed}`
  );

  return data;
});

export const createAuction = createAsyncThunk(
  'auctions/create',
  async (date: createAuctionRequestDateI) => {
    const { data } = await instance.post(`/auctions/create`, date);

    return data;
  }
);

export const changeAuction = createAsyncThunk(
  'auctions/update',
  async (date: createAuctionRequestDateI) => {
    const { data } = await instance.patch(`/auctions/update`, date);

    return data;
  }
);

export const closeAuction = createAsyncThunk('auctions/close', async (id: number) => {
  const { data } = await instance.post(`/auctions/close`, { id });

  return data;
});

export const deleteAuction = createAsyncThunk('auctions/delete', async (id: number) => {
  const { data } = await instance.delete(`/auctions/remove?id=${id}`);

  return data;
});
