// import { useAppDispatch, useAppSelector } from '@/app/store';
// import { setActiveChat } from '@/entities/chats/model';
import { useState } from 'react';

import styles from './ChatList.module.scss';
import { ChatItemT } from './Components/ChatItem/ChatItem';
import { ChatItemList } from './Components/ChatItemList';
import { CurrentMessageItem } from './Components/CurrentMessageItem';
import { ChatItemListPropsI } from './Components/ChatItemList/ChatItemList.props';
import { HeaderCurrentUserDialog } from './Components/HeaderCurrentUserDialog';

type Messages = {
  name: string;
  lastMessage: string;
  time: string;
  unreadMessage: number;
};

export const ChatList = ({ chatRooms }: { chatRooms: Array<ChatItemT> }) => {
  const [currentUserDialogs, setCurrentUserDialogs] = useState<Messages[] | null>(null);
  const [fiendedDialog, setFiendedDialog] = useState<ChatItemListPropsI | null>(null);
  const mockData = [
    {
      name: 'kljdnfhjf_00',
      dialogs: 4,
      unreadMessage: 0,
      time: '15:36',
      isFavorite: false,
      isAnchored: true,
      lastMessage: 'test',
      id: 1,
      messages: [
        { name: 'Анжела', lastMessage: 'testtesttest test test', time: '15:36', unreadMessage: 2 },
        { name: 'Анжела', lastMessage: 'testtesttest test test', time: '15:36', unreadMessage: 2 },
        { name: 'Анжела', lastMessage: 'testtesttest test test', time: '15:36', unreadMessage: 2 },
        { name: 'Анжела', lastMessage: 'testtesttest test test', time: '15:36', unreadMessage: 2 },
        { name: 'Анжела', lastMessage: 'testtesttest test test', time: '15:36', unreadMessage: 2 },
      ],
    },
    {
      name: 'kisolik',
      dialogs: 8,
      unreadMessage: 2,
      time: '15:36',
      isFavorite: true,
      isAnchored: false,
      lastMessage: 'test',
      id: 2,
      messages: [
        { name: 'Анжела', lastMessage: 'testtesttest test test', time: '15:36', unreadMessage: 2 },
        { name: 'Анжела', lastMessage: 'testtesttest test test', time: '15:36', unreadMessage: 2 },
        { name: 'Анжела', lastMessage: 'testtesttest test test', time: '15:36', unreadMessage: 2 },
        { name: 'Анжела', lastMessage: 'testtesttest test test', time: '15:36', unreadMessage: 2 },
        { name: 'Анжела', lastMessage: 'testtesttest test test', time: '15:36', unreadMessage: 2 },
      ],
    },
    {
      name: 'abroooo',
      dialogs: 1,
      unreadMessage: 2,
      time: '15:36',
      isFavorite: false,
      isAnchored: false,
      lastMessage: 'test',
      id: 3,
      messages: [
        { name: 'Анжела', lastMessage: 'testtesttest test test', time: '15:36', unreadMessage: 2 },
        { name: 'Анжела', lastMessage: 'testtesttest test test', time: '15:36', unreadMessage: 2 },
        { name: 'Анжела', lastMessage: 'testtesttest test test', time: '15:36', unreadMessage: 2 },
        { name: 'Анжела', lastMessage: 'testtesttest test test', time: '15:36', unreadMessage: 2 },
        { name: 'Анжела', lastMessage: 'testtesttest test test', time: '15:36', unreadMessage: 2 },
      ],
    },
    {
      name: 'cerega',
      dialogs: 4,
      unreadMessage: 2,
      time: '15:36',
      isFavorite: true,
      isAnchored: true,
      lastMessage: 'test',
      id: 4,
      messages: [
        { name: 'Анжела', lastMessage: 'testtesttest test test', time: '15:36', unreadMessage: 2 },
        { name: 'Анжела', lastMessage: 'testtesttest test test', time: '15:36', unreadMessage: 2 },
        { name: 'Анжела', lastMessage: 'testtesttest test test', time: '15:36', unreadMessage: 2 },
        { name: 'Анжела', lastMessage: 'testtesttest test test', time: '15:36', unreadMessage: 2 },
      ],
    },
  ];

  const handleClickCurrentDialog = (id: number) => {
    const currentDialog = mockData.find((obj) => obj.id === id);

    if (currentDialog) {
      setFiendedDialog(currentDialog as any);
      setCurrentUserDialogs(currentDialog.messages);
    }
  };

  return (
    <div className={`${styles.box} scroll`}>
      {currentUserDialogs && (
        <HeaderCurrentUserDialog
          setCurrentUserDialogs={setCurrentUserDialogs}
          name={fiendedDialog?.name}
          dialogs={fiendedDialog?.dialogs}
        />
      )}
      <ul className={styles.list}>
        {!currentUserDialogs
          ? mockData.map(
              ({
                name,
                id,
                dialogs,
                lastMessage,
                unreadMessage,
                messages,
                time,
                isAnchored,
                isFavorite,
              }) => (
                <li key={id} className={styles.item}>
                  <ChatItemList
                    handleClickCurrentDialog={handleClickCurrentDialog}
                    key={id}
                    messages={messages}
                    name={name}
                    id={id}
                    dialogs={dialogs}
                    time={time}
                    isAnchored={isAnchored}
                    isFavorites={isFavorite}
                    unreadMessage={unreadMessage}
                    lastMessage={lastMessage}
                  />
                </li>
              )
            )
          : currentUserDialogs.map(({ unreadMessage, time, name, lastMessage }, index) => (
              <li key={index} className={styles.item}>
                <CurrentMessageItem
                  name={name}
                  unreadMessage={unreadMessage}
                  time={time}
                  lastMessage={lastMessage}
                />
              </li>
            ))}
      </ul>
    </div>
  );
};
