import { Modal } from 'shared/ui/Modal';
import { Button } from 'shared/ui/Button';
import ROUTES from 'shared/constans/routes';
import { useNavigate } from 'react-router-dom';

import { RestartAuctionModalPropsI } from './RestartAuctionModal.props';
import styles from './RestartAuctionModal.module.scss';

export const RestartAuctionModal = ({ isVisible, close, data }: RestartAuctionModalPropsI) => {
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`${ROUTES.CHANGE_AUCTION}/${data.id}`, {
      replace: true,
      state: data,
    });
  };

  return (
    <Modal isVisible={isVisible} close={close}>
      <p className={styles.title}>
        Повторить аукцион <br />
        без изменений?
      </p>
      <p className={styles.text}>Аукцион будет опубликован в прежнем виде, но как новый</p>
      <div className={styles.buttons_container}>
        <Button additionalClasses={styles.button} onClick={handleEdit} type={4}>
          Редактировать
        </Button>
        <Button additionalClasses={styles.button} onClick={close} type={3}>
          Да, опубликовать
        </Button>
      </div>
    </Modal>
  );
};
