// import { useEffect } from 'react';

// import { useAppDispatch, useAppSelector } from '@/app/store/hooks';

// import { getOperations } from '../../api';
import { OperationType } from 'entities/operations/types';
import {
  generateOperationLink,
  generateOperationText,
} from 'entities/operations/helpers/generateOperationLink';
import { Input } from 'shared/ui/Input';

import styles from './OperationsTable.module.scss';
// import { generateOperationLink } from '../../helpers/generateOperationLink';
// import { clearOperations } from '../../model';
type OperationsTableT = {
  additionalClasses?: string;
};
export const OperationsTable = ({ additionalClasses }: OperationsTableT) => {
  // const dispatch = useAppDispatch();
  // const { operations } = useAppSelector((state) => state.operations);
  // const { user } = useAppSelector((state) => state.user);

  // useEffect(() => {
  //   return () => {
  //     dispatch(clearOperations());
  //   };
  // }, []);
  // useEffect(() => {
  //   if (user && !operations.length) {
  //     dispatch(getOperations(user.id));
  //   }
  // }, [user, operations, dispatch]);
  const operations: Array<OperationType<any>> = [
    {
      id: 949,
      userId: 14,
      action: 'down',
      sum: 800,
      type: 'catalog',
      typeId: 5,
      createdAt: '2023-12-29T12:49:42.482Z',
      details: {
        id: 5,
        title: {
          ru: 'ALL WEST',
          en: 'ALL WEST',
          de: '',
        },
      },
    },
    {
      id: 948,
      userId: 14,
      action: 'up',
      sum: 800,
      type: 'payment',
      typeId: 519,
      createdAt: '2023-12-29T12:49:42.482Z',
      details: null,
    },
    {
      id: 929,
      userId: 14,
      action: 'down',
      sum: 3990,
      type: 'pre-order-catalog',
      typeId: 7,
      createdAt: '2023-12-20T10:39:25.298Z',
      details: {
        id: 7,
        title: {
          ru: 'Продажа невинности',
          en: 'Sale of Virginity',
          de: '',
        },
      },
    },
    {
      id: 928,
      userId: 14,
      action: 'up',
      sum: 3990,
      type: 'payment',
      typeId: 509,
      createdAt: '2023-12-20T10:39:25.298Z',
      details: null,
    },
    {
      id: 896,
      userId: 14,
      action: 'up',
      sum: 8000,
      type: 'payment',
      typeId: 493,
      createdAt: '2023-12-14T13:46:55.200Z',
      details: null,
    },
    {
      id: 895,
      userId: 14,
      action: 'down',
      sum: 100,
      type: 'questionnaire',
      typeId: 5,
      createdAt: '2023-12-14T13:38:22.184Z',
      details: {
        id: 5,
        firstName: 'Фаа3',
        age: 31,
      },
    },
    {
      id: 894,
      userId: 14,
      action: 'down',
      sum: 100,
      type: 'questionnaire',
      typeId: 4,
      createdAt: '2023-12-14T13:38:22.064Z',
      details: {
        id: 4,
        firstName: 'Фаа2',
        age: 23,
      },
    },
    {
      id: 893,
      userId: 14,
      action: 'down',
      sum: 100,
      type: 'questionnaire',
      typeId: 3,
      createdAt: '2023-12-14T13:38:21.940Z',
      details: {
        id: 3,
        firstName: 'Фаа',
        age: 53,
      },
    },
    {
      id: 892,
      userId: 14,
      action: 'down',
      sum: 100,
      type: 'questionnaire',
      typeId: 2,
      createdAt: '2023-12-14T13:38:21.820Z',
      details: {
        id: 2,
        firstName: 'Ааааааааа',
        age: 21,
      },
    },
    {
      id: 816,
      userId: 14,
      action: 'down',
      sum: 100,
      type: 'questionnaire',
      typeId: 1,
      createdAt: '2023-12-12T18:34:03.942Z',
      details: {
        id: 1,
        firstName: 'Лалалал',
        age: 20,
      },
    },
  ];
  return (
    <div className={`${styles.table} block ${additionalClasses || ''}`}>
      <div className={styles['inputs-block']}>
        <Input type={2} value={'7 800$'} tip="Депозит" />
        <Input type={2} value={'58 000$'} tip="Сумма (потраченная на сервисе)" />
      </div>
      {operations.map((o: OperationType<any>) => {
        const Link = generateOperationLink(o);
        const Type = generateOperationText(o);
        return (
          <div className={styles.row} key={o.id}>
            <div className={styles.date}>{new Date(o.createdAt).toLocaleDateString('ru-Ru')}</div>
            <div className={styles.action}>
              {o.action === 'down' ? 'Списание' : 'Пополнение'}
              {o.action === 'down' && `: ${Type}`} {Link}
            </div>
            <div className={`${styles.sum} ${styles[o.action]}`}>
              {o.action === 'up' ? '+' : '-'} {o.sum} €
            </div>
          </div>
        );
      })}
    </div>
  );
};
