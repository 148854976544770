import { ReactComponent as MessageIcon } from 'shared/assets/icons/message.svg';
import { ReactComponent as UserIcon } from 'shared/assets/icons/user-cirlce-add.svg';
import { ReactComponent as StarIcon } from 'shared/assets/icons/star1.svg';
import { ReactComponent as ProfileIcon } from 'shared/assets/icons/profile-circle.svg';

import styles from './ChatFilters.module.scss';

export const ChatFilters = () => {
  const filters = [
    { id: 1, name: 'Все чаты', icon: <MessageIcon /> },
    { id: 1, name: 'Избранные', icon: <StarIcon /> },
    { id: 1, name: 'Резиденты', icon: <ProfileIcon /> },
    { id: 1, name: 'Новые', icon: <UserIcon /> },
  ];
  return (
    <ul className={styles.list}>
      {filters.map(({ id, name, icon }) => (
        <li className={styles.item} key={id}>
          <button className={styles.button}>
            <span className={styles.icon}>{icon}</span>
            <p className={styles.text}>{name}</p>
          </button>
        </li>
      ))}
    </ul>
  );
};
