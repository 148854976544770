import { Link } from 'react-router-dom';

import styles from './Button.module.scss';
import { ButtonT } from './Button.props';

export const Button = ({
  additionalClasses = '',
  children,
  type,
  onClick,
  disabled,
  tag = 'button',
  href,
}: ButtonT) => {
  return tag === 'button' ? (
    <button
      className={`${styles['btn-' + type]} ${additionalClasses}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  ) : (
    <Link className={`${styles['btn-' + type]} ${additionalClasses}`} to={href ?? ''}>
      {children}
    </Link>
  );
};
