import styles from './DateBlock.module.scss';
type DateBlockT = {
  date: string;
};
export const DateBlock = ({ date }: DateBlockT) => {
  return (
    <div className={styles.date} suppressHydrationWarning>
      {new Date(date).toLocaleDateString()}
    </div>
  );
};
