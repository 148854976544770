import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { instance as axiosInstance } from '../../shared/config/api/api';

interface AuthState {
  isAuthenticated: boolean;
  authLoading: boolean;
  error: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  authLoading: false,
  error: null,
};

export const login = createAsyncThunk(
  'auth/login',
  async (credentials: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/auth-admin/login', credentials);
      const { accessToken } = response.data;
      localStorage.setItem('accessToken', accessToken);
      return accessToken;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const refresh = createAsyncThunk('auth/refreshToken', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/auth-admin/token');
    const { accessToken } = response.data;
    localStorage.setItem('accessToken', accessToken);
    return { accessToken };
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});

export const authLogout = createAsyncThunk('auth/logout', async (_, { rejectWithValue }) => {
  try {
    await axiosInstance.post('/auth-admin/logout');
    return {};
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.authLoading = false;
      localStorage.removeItem('accessToken');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.authLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state) => {
        state.authLoading = false;
        state.isAuthenticated = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.authLoading = false;
        state.error = action.payload as string;
      })
      .addCase(refresh.pending, (state) => {
        state.authLoading = true;
        state.error = null;
      })
      .addCase(refresh.fulfilled, (state) => {
        state.authLoading = false;
        state.isAuthenticated = true;
      })
      .addCase(refresh.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.authLoading = false;
        localStorage.removeItem('accessToken');
        state.error = action.payload as string;
      })
      .addCase(authLogout.pending, (state) => {
        state.authLoading = true;
        state.error = null;
      })
      .addCase(authLogout.fulfilled, (state) => {
        state.authLoading = false;
        state.isAuthenticated = false;
        localStorage.removeItem('accessToken');
      })
      .addCase(authLogout.rejected, (state, action) => {
        state.authLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
