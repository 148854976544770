import { IMAGES_URL } from 'shared/config/urls';

import { ReactComponent as TrashIcon } from '../../../../shared/assets/icons/trash.svg';
import styles from './PhotoListItem.module.scss';
import { PhotoListItemPropsI } from './PhotoListItem.props';

export const PhotoListItem = ({ handleRemove, preview }: PhotoListItemPropsI) => {
  return (
    <li className={styles.item} key={`${preview}`}>
      <img className={styles.image} src={`${IMAGES_URL}${preview || ''}`} alt="test" />
      <button onClick={() => handleRemove(preview)}>
        <TrashIcon className={styles.trash_icon} />
      </button>
    </li>
  );
};
