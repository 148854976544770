import { Select } from 'shared/ui/Select';
import { SelectProps } from 'shared/ui/Select/Select.props';
import { useState } from 'react';

import styles from './CatalogSelectsWidget.module.scss';

export const CatalogSelectsWidget = () => {
  const [selects, setSelects] = useState<Array<Omit<SelectProps, 'onClickItem'> & { id: number }>>([
    {
      id: 1,
      tip: 'Дата добавления',
      tipAsPlaceholder: true,
      placeholder: '',
      currentOption: 1,
      v: 2,
      options: [
        {
          id: 1,
          value: '12.01.1990',
        },
        {
          id: 2,
          value: '13.02.1990',
        },
      ],
    },
    {
      id: 2,
      tip: 'Формат знакомств',
      tipAsPlaceholder: true,
      placeholder: '',
      currentOption: 2,
      v: 2,
      options: [
        {
          id: 1,
          value: 'джиги дрыги',
        },
        {
          id: 2,
          value: 'сварить борща',
        },
        {
          id: 3,
          value: 'клининг',
        },
      ],
    },
    {
      id: 3,
      tip: 'Страна',
      tipAsPlaceholder: true,
      placeholder: '',
      currentOption: 3,
      v: 2,
      options: [
        {
          id: 1,
          value: 'Россия',
        },
        {
          id: 2,
          value: 'Германия',
        },
        {
          id: 3,
          value: 'Франция',
        },
      ],
    },
    {
      id: 4,
      tip: 'Город',
      tipAsPlaceholder: true,
      placeholder: '',
      currentOption: 3,
      v: 2,
      options: [
        {
          id: 1,
          value: 'Москва',
        },
        {
          id: 2,
          value: 'Берлин',
        },
        {
          id: 3,
          value: 'Париж',
        },
      ],
    },
  ]);
  return (
    <div className={styles.block}>
      {selects.map((s) => {
        const { id, ...rest } = s;
        return (
          <Select
            key={id}
            {...rest}
            onClickItem={(optId) => {
              setSelects((prev) =>
                prev.map((p) => (p.id === id ? { ...p, currentOption: optId } : p))
              );
            }}
          />
        );
      })}
    </div>
  );
};
