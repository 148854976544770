// import { useTranslation } from 'next-i18next';

import { Modal } from 'shared/ui/Modal';
import { Button } from 'shared/ui/Button';
import { UnsubscribeBtn } from 'features/subscriptions/UnsubscribeBtn';

import { CancelSubscriptionModalT } from './CancelSubscriptionModalT';
import styles from './CancelSubscriptionModal.module.scss';
export const CancelSubscriptionModal = ({
  name,
  validUntil,
  show,
  subscriptionId,
  close,
}: CancelSubscriptionModalT) => {
  // const { t } = useTranslation(['subscription', 'buttons']);
  return (
    <Modal isVisible={show} close={close} additionalClasses={styles.modal}>
      <>
        {/* <p className={styles.title}>{subscriptionId}</p> */}
        <p className={styles.title}>Отменить подписку на {name}?</p>
        <div className={styles.infoblock}>
          <p className={styles.infoleft}>При отмене подписка будет действовать еще до:</p>
          <p className={styles.inforight}>{validUntil}</p>
        </div>

        <div className={styles.buttonsblock}>
          <Button type={3} onClick={close} additionalClasses={styles.no}>
            нет
          </Button>
          {/* <Button type={4} additionalClasses={styles.yes}>
            {t('modal.yesCancel')}
          </Button> */}
          <UnsubscribeBtn subscriptionId={subscriptionId} close={close} />
        </div>
      </>
    </Modal>
  );
};
