import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import styles from './SideBar.module.scss';
import { DetailsNavLink } from './constans';
import { SideBarProps } from './SideBar.props';

export const SideBar = ({ className }: SideBarProps) => {
  const { pathname } = useLocation();

  return (
    <nav className={`${className}`}>
      <ul className={styles.list}>
        {DetailsNavLink.map(({ link, id, Icon, name }) => (
          <li className={styles.item} key={id}>
            {pathname.includes(link) ? (
              <span className={styles.active_icon}>{<Icon />}</span>
            ) : (
              <Icon />
            )}
            <Link
              className={classNames(
                styles.item_link,
                pathname.includes(link) && styles.active_item_link
              )}
              to={link}
            >
              {name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};
