import { Button } from 'shared/ui/Button';
import { ReactComponent as SearchSVG } from 'shared/assets/icons/search.svg';
import { ReactComponent as MoreSVG } from 'shared/assets/icons/more.svg';
import { DropDownButtons } from 'shared/ui/DropDownButtons/DropDownButtons';
import { useDropDownResize } from 'shared/hooks/useDropDownResize';

import styles from './ChatHeader.module.scss';
export const ChatHeader = ({ name }: { name: string | null }) => {
  const { setIsVisible, isVisible, parentalDropdownRef, positions, setPositions } =
    useDropDownResize();
  const buttons = [
    { id: 1, name: 'Профиль', onClick: () => {} },
    { id: 2, name: 'Заблокировать', onClick: () => {} },
    { id: 3, name: 'Удалить этот чат', onClick: () => {} },
  ];

  return (
    <div className={styles.box}>
      <span className={styles.name}>{name && name === 'manager' ? 'Менеджер' : name}</span>
      <div className={styles['btn-container']}>
        <Button type={1}>
          <SearchSVG />
        </Button>

        <button
          onClick={() => setIsVisible(true)}
          ref={parentalDropdownRef}
          className={styles.button}
        >
          <MoreSVG />
        </button>
      </div>
      <DropDownButtons
        buttons={buttons}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        rectValue={positions}
        setPositions={setPositions}
      />
    </div>
  );
};
