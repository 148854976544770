import { Select } from 'shared/ui/Select';
import { Input } from 'shared/ui/Input';
import { FormType } from 'shared/types/FormType/FormType';
import { useForm } from 'shared/hooks/useForm/useForm';

import styles from './ChangeForm.module.scss';
import { ChangeFormPropsI } from './ChangeForm.props';
import { YouDontSaveModal } from '../YouDontSaveModal';

export const ChangeForm = () => {
  const initialForm: FormType = {
    name: {
      type: 'input',
      id: 1,
      isValid: false,
      placeholder: 'Имя',
      isRequired: true,
      value: '',
      errorText: '',
    },
    country: {
      type: 'select',
      currentOption: 0,
      isValid: false,
      placeholder: 'Страна',
      isRequired: true,
      errorText: '',
      id: 2,
    },
    city: {
      type: 'select',
      currentOption: 0,
      isValid: false,
      placeholder: 'Город',
      isRequired: true,
      errorText: '',
      id: 3,
    },
    format: {
      type: 'select',
      id: 4,
      placeholder: 'Формат знакомства',
      isRequired: false,
      isValid: false,
      errorText: '',
    },
    catalog: {
      type: 'select',
      id: 5,
      placeholder: 'Каталог',
      isRequired: false,
      isValid: false,
      errorText: '',
    },
    character: {
      type: 'select',
      id: 6,
      placeholder: 'Типаж',
      isRequired: false,
      isValid: false,
      errorText: '',
    },
    height: {
      type: 'input',
      id: 7,
      isValid: false,
      placeholder: 'Рост',
      isRequired: true,
      value: '',
      errorText: '',
    },
    weight: {
      type: 'input',
      id: 8,
      placeholder: 'Вес',
      isRequired: false,
      isValid: false,
      errorText: '',
    },
    breast: {
      type: 'input',
      id: 9,
      placeholder: 'Грудь',
      isRequired: false,
      isValid: false,
      errorText: '',
    },
    year: {
      type: 'input',
      currentOption: 0,
      isValid: false,
      placeholder: 'Возраст',
      isRequired: true,
      errorText: '',
      id: 10,
    },
    youtube: {
      type: 'input',
      id: 11,
      placeholder: 'Ссылка Instagram',
      isRequired: false,
      isValid: false,
      errorText: '',
    },
    instagram: {
      type: 'input',
      id: 12,
      placeholder: 'Ссылка Youtube',
      isRequired: false,
      isValid: false,
      errorText: '',
    },
    publish: {
      type: 'select',
      id: 13,
      placeholder: 'Запланировать публикацию',
      isRequired: false,
      isValid: false,
      errorText: '',
    },
    time: {
      type: 'select',
      id: 14,
      placeholder: 'Ограничение времени на сервисе',
      isRequired: false,
      isValid: false,
      errorText: '',
    },
  };

  const { form, handleChangeForm, isValidForm, isChangedForm } = useForm(initialForm);
  const firstRowFormKeys = Object.keys(form).slice(0, 3);
  const secondRowFormKeys = Object.keys(form).slice(3, 6);
  const thirdRowFormKeys = Object.keys(form).slice(6, 10);
  const fourRowFormKeys = Object.keys(form).slice(10, 14);

  return (
    <div className={styles.container}>
      <div className={styles.top_content}>
        {firstRowFormKeys.map((key) =>
          form[key].type === 'select' ? (
            <div key={form[key].id}>
              <Select
                className={styles.select}
                tip={form[key].placeholder}
                v={2}
                options={[
                  { value: 'тест1', id: 0 },
                  { value: 'тест2', id: 1 },
                ]}
                tipAsPlaceholder={true}
                onClickItem={() => {}}
                currentOption={''}
              />
              {form[key].errorText && (
                <span className={styles.valid_error}>{form[key].errorText}</span>
              )}
            </div>
          ) : (
            <div key={form[key].id}>
              <Input
                type={2}
                value={form[key].value || ''}
                tip={form[key].placeholder}
                tipAsPlaceholder={true}
                setValue={(val) => handleChangeForm(key, val)}
              />
              {form[key].errorText && (
                <span className={styles.valid_error}>{form[key].errorText}</span>
              )}
            </div>
          )
        )}
      </div>
      <div className={styles.middle_contanet}>
        {secondRowFormKeys.map((key) => (
          <div key={form[key].id}>
            <Select
              className={styles.select}
              tip={form[key].placeholder}
              v={2}
              options={[
                { value: 'тест1', id: 0 },
                { value: 'тест2', id: 1 },
              ]}
              tipAsPlaceholder={true}
              onClickItem={() => {}}
              currentOption={''}
            />
            {form[key].errorText && (
              <span className={styles.valid_error}>{form[key].errorText}</span>
            )}
          </div>
        ))}
      </div>
      <div className={styles.bottom_content}>
        {thirdRowFormKeys.map((key) => (
          <div key={form[key].id}>
            <Input
              type={2}
              value={form[key].value || ''}
              tip={form[key].placeholder}
              tipAsPlaceholder={true}
              setValue={(val) => handleChangeForm(key, val)}
            />
            {form[key].errorText && (
              <span className={styles.valid_error}>{form[key].errorText}</span>
            )}
          </div>
        ))}
      </div>
      <div className={styles.four_container}>
        {fourRowFormKeys.map((key) =>
          form[key].type === 'select' ? (
            <div key={form[key].id}>
              {' '}
              <Select
                className={styles.select}
                tip={form[key].placeholder}
                v={2}
                tipAsPlaceholder={true}
                options={[
                  { value: 'тест1', id: 0 },
                  { value: 'тест2', id: 1 },
                ]}
                onClickItem={() => {}}
                currentOption={''}
              />
              {form[key].errorText && (
                <span className={styles.valid_error}>{form[key].errorText}</span>
              )}
            </div>
          ) : (
            <div key={form[key].id}>
              <Input
                type={2}
                value={form[key].value || ''}
                tip={form[key].placeholder}
                tipAsPlaceholder={true}
                setValue={(val) => handleChangeForm(key, val)}
              />
              {form[key].errorText && (
                <span className={styles.valid_error}>{form[key].errorText}</span>
              )}
            </div>
          )
        )}
      </div>
      {/* <YouDontSaveModal /> */}
    </div>
  );
};
