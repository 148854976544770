import { ReferalsBanner } from 'widgets/Referals/ReferalsBanner';
import { ReferalsList } from 'widgets/Referals/ReferalsList';

export const ReferalTab = () => {
  return (
    <div>
      <ReferalsBanner />
      <ReferalsList />
    </div>
  );
};
