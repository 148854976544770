import { useState } from 'react';
import { notificationsBotApi } from 'shared/config/api/api';
import { TypeBotNatificationT } from 'pages/AdminPage/interfaces';

import { TableCell } from '../TableCell';
import styles from './TableRow.module.scss';
import { TableRowPropsI } from './TableRow.props';

export const TableRow = ({
  name,
  text,
  period,
  stipulation,
  rowId,
  params,
  setParams,
  token,
}: TableRowPropsI) => {
  const findIntitialRowData = params.find(({ id }: any) => rowId === id);
  const [currentRowId, setCurrentRowId] = useState<null | number>(null);
  const [rowData, setRowData] = useState(findIntitialRowData);

  const handleUnlockEditingRow = async (index: number) => {
    const currentRow = params.find(({ id }) => index === id)?.id;
    const currentRowObject = params.find(({ id }: any) => index === id);

    if (currentRowId === index) {
      setCurrentRowId(0);
      console.log('ДАННЫЕ ОТПРАВЛЕНЫ', rowId, rowData);
      await notificationsBotApi.editNotificationBot(rowData, token);
      await notificationsBotApi.getAllNotificationBot(token).then((res) => setParams(res.data));
    } else {
      setCurrentRowId(0);
      setRowData(currentRowObject);
      if (currentRow) {
        setCurrentRowId(currentRow);
      }
    }
  };

  const DetailsRow = [
    {
      rowData: rowData,
      type: 'name',
      id: rowId,
      cell: name,
      params: params,
      setRowData: setRowData,
      currentRowId: currentRowId,
    },
    {
      rowData: rowData,
      type: 'text',
      id: rowId,
      cell: text,
      params: params,
      setRowData: setRowData,
      currentRowId: currentRowId,
    },
    {
      rowData: rowData,
      type: 'period',
      id: rowId,
      cell: period,
      params: params,
      setRowData: setRowData,
      currentRowId: currentRowId,
    },
    {
      rowData: rowData,
      type: 'stipulation',
      id: rowId,
      cell: stipulation,
      params: params,
      setRowData: setRowData,
      currentRowId: currentRowId,
    },
    {
      rowData: rowData,
      cell: 'button',
      currentRowId: currentRowId,
      id: rowId,
      handleUnlockEditingRow: handleUnlockEditingRow,
    },
  ];

  return (
    <tr className={styles.row}>
      {DetailsRow.map(
        ({ rowData, setRowData, currentRowId, handleUnlockEditingRow, cell, params, id, type }) => (
          <TableCell
            rowData={rowData}
            type={type as TypeBotNatificationT}
            id={id}
            currentRowId={currentRowId}
            cell={cell}
            params={params}
            setRowData={setRowData}
            handleUnlockEditingRow={handleUnlockEditingRow}
          />
        )
      )}
      {/* <TableCell
        rowData={rowData}
        type={'name'}
        id={rowId}
        currentRowId={currentRowId}
        cell={name}
        params={params}
        setRowData={setRowData}
      />
      <TableCell
        rowData={rowData}
        type={'text'}
        id={rowId}
        currentRowId={currentRowId}
        cell={text}
        params={params}
        setRowData={setRowData}
      />
      <TableCell
        rowData={rowData}
        type={'period'}
        id={rowId}
        currentRowId={currentRowId}
        cell={period}
        params={params}
        setRowData={setRowData}
      />
      <TableCell
        rowData={rowData}
        id={rowId}
        type={'stipulation'}
        currentRowId={currentRowId}
        cell={stipulation}
        params={params}
        setRowData={setRowData}
      />
      <TableCell
        rowData={rowData}
        cell={'button'}
        currentRowId={currentRowId}
        id={rowId}
        handleUnlockEditingRow={handleUnlockEditingRow}
      /> */}
    </tr>
  );
};
