import { createSlice } from '@reduxjs/toolkit';

import { getAllQuestionnaires } from '../api';
import { QuestionnairesInitialState } from './interfaces';

const initialState: QuestionnairesInitialState = {
  questionnaires: [],
};
const questionnairesSlice = createSlice({
  name: 'questionnaires',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all questionnaires
    builder.addCase(getAllQuestionnaires.pending, (state) => {
      state.questionnaires = [];
    });
    builder.addCase(getAllQuestionnaires.fulfilled, (state, action) => {
      state.questionnaires = action.payload;
    });
    builder.addCase(getAllQuestionnaires.rejected, (state) => {
      state.questionnaires = [];
    });
  },
});
export const {} = questionnairesSlice.actions;
export default questionnairesSlice.reducer;
