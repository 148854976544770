import ROUTES from 'shared/constans/routes';

import { Tabs } from '../../shared/ui/Tabs';
import { AllAuctions } from './Tabs/AllAuctions';
import { ClosedAuctions } from './Tabs/ClosedAuctions';
import styles from './Auctions.module.scss';
import { Button } from '../../shared/ui/Button';
import { ReactComponent as AddSquareIcon } from '../../shared/assets/icons/addsquare.svg';
const Auctions = () => {
  const tabs = [
    {
      content: <AllAuctions />,
      label: 'Все аукционы',
      id: 0,
    },
    {
      content: <ClosedAuctions />,
      label: 'Закрытые',
      id: 1,
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <div className={styles.banner}>
        <p className={styles.title}>Аукционы</p>
        <Button additionalClasses={styles.button} type={4} tag="link" href={ROUTES.CREATE_AUCTION}>
          <AddSquareIcon />
          <span>Создать аукцион</span>
        </Button>
      </div>
      <Tabs tabs={tabs} />
    </div>
  );
};

export default Auctions;
