import StarSVG from 'shared/assets/ui/chatItem/star2.svg';

import styles from './ThanksForDonationMessage.module.scss';

export const ThanksForDonationMessage = () => {
  return (
    <div className={styles.box}>
      <p className={styles.title}>
        <StarSVG />
        <span>{'Спасибо за донат!'}</span>
      </p>
      <p className={styles.desc}>
        {'С Вашей помощью мы улучшаем качество и работу нашего сервиса'}
      </p>
    </div>
  );
};
