import { memo } from 'react';

import userImageDefoult from '../../../../shared/assets/mock/mockphoto.png';
import styles from './AnotherUserComments.module.scss';
import { AnotherUserCommentPropsI } from './AnotherUserComments.props';

export const AnotherUserComment = memo(({ message, time, name }: AnotherUserCommentPropsI) => {
  return (
    <div className={styles.container}>
      <img src={userImageDefoult} width={24} height={24} alt="user photo" />
      <div className={styles.message_block}>
        <div className={styles.user_block}>
          <span className={styles.name}>{name}</span>
          <p className={styles.message}>{message}</p>
        </div>
        <span className={styles.time}>{time}</span>
      </div>
    </div>
  );
});

AnotherUserComment.displayName = 'AnotherUserComments';
