import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';

type TimeLeftType = {
  hours: string | null;
  minutes: string | null;
  seconds: string | null;
};

export const useTimer = (timestamp: Date) => {
  const [timeLeft, setTimeLeft] = useState<TimeLeftType>({
    hours: null,
    minutes: null,
    seconds: null,
  });
  const [isFinishTimer, setIsFinishTimer] = useState(false);
  const { hours, minutes, seconds } = timeLeft;

  useEffect(() => {
    const interval = setInterval(() => {
      const now = dayjs();
      const targetTime = dayjs(timestamp);
      const diff = targetTime.diff(now, 'second');
      const hours = Math.floor(diff / 3600)
        .toString()
        .padStart(2, '0');
      const minutes = Math.floor((diff % 3600) / 60)
        .toString()
        .padStart(2, '0');
      const seconds = (diff % 60).toString().padStart(2, '0');

      setTimeLeft({ hours, minutes, seconds });

      if (diff <= 0) {
        setIsFinishTimer(true);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timestamp]);

  return { hours, minutes, seconds, isFinishTimer };
};
