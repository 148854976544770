import { MutableRefObject, useEffect, MouseEvent, useRef, useState } from 'react';

import { useOutsideClick } from '../useOutsideClick/useOutsideClick';

export const useContextMenu = (parrentRef?: MutableRefObject<HTMLElement | null | Document>) => {
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [isVisibleMenu, setIsVisibleMenu] = useState(false);
  const dropdownRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsVisibleMenu(false);
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  console.log(menuPosition);

  const handleContextMenu = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    setMenuPosition({
      x: event.clientX + 10,
      y: event.pageY,
    });
    setIsVisibleMenu(true);
  };

  useOutsideClick(dropdownRef, () => setIsVisibleMenu(false), isVisibleMenu);

  return { menuPosition, handleContextMenu, isVisibleMenu, dropdownRef };
};
