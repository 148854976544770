import { useState } from 'react';

import { UserList } from '../../../../widgets/Users/UserList';
import { SelectFilters } from '../../../../widgets/SelectFilters';

export const AllUsers = () => {
  const [selects, setSelects] = useState({
    status: 0,
    meet: 0,
    country: 0,
    city: 0,
  });

  const test = [
    {
      value: 'test',
      id: 1,
    },
    {
      value: 'test2',
      id: 2,
    },
    {
      value: 'test3',
      id: 3,
    },
  ];

  const { status, meet, country, city } = selects;

  const DetailsSelect = [
    {
      options: test,
      placeholder: 'Дата регистрации',
      onClickItem: (id: number) => {
        setSelects({ ...selects, meet: id });
      },
      currentOption: status,
    },
    {
      options: test,
      placeholder: 'Сумма',
      onClickItem: (id: number) => {
        setSelects({ ...selects, meet: id });
      },
      currentOption: meet,
    },
    {
      options: test,
      placeholder: 'Статус',
      onClickItem: (id: number) => {
        setSelects({ ...selects, country: id });
      },
      currentOption: country,
    },
    {
      options: test,
      placeholder: 'Заказы',
      onClickItem: (id: number) => {
        setSelects({ ...selects, city: id });
      },
      currentOption: city,
    },
  ];
  return (
    <div>
      <SelectFilters detailsSelect={DetailsSelect} />
      <UserList />
    </div>
  );
};
