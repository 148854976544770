import { useState } from 'react';

import styles from './SubcategoryList.module.scss';
import { SubcategoriesPropsI } from './SubcategoryList.props';

export const SubcategoryList = ({ subcategories, className }: SubcategoriesPropsI) => {
  const [selectedSubcategory, setSelectedSubcategory] = useState<null | number>(null);

  const handleChangeSelectedSubcategory = (id: number) => {
    setSelectedSubcategory(id);
  };
  return (
    <ul className={`${styles.list} ${className} `}>
      {subcategories.map(({ id, name }) => (
        <li className={styles.item} key={id}>
          <button onClick={() => handleChangeSelectedSubcategory(id)} className={styles.text}>
            {name}
          </button>
        </li>
      ))}
    </ul>
  );
};
