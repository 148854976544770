import { useEffect, useRef } from 'react';

import { UserComment } from '../UserComment/UserComment';
import styles from './CommentsView.module.scss';
import { CommentsViewPropsI, UserType } from './CommentsView.props';
import { AnotherUserComment } from '../AnotherUserComments/AnotherUserComments';

export const CommentsView = ({ commentsData }: CommentsViewPropsI) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const isYouMessage = commentsData[commentsData.length - 1].type === UserType.User;
    if (isYouMessage) {
      containerRef.current?.scrollTo({
        top: containerRef.current.scrollHeight,
      });
    }
  }, [commentsData.length]);

  return (
    <div ref={containerRef} className={styles.container}>
      <p className={styles.title}>Начало обсуждения</p>
      <ul className={styles.list}>
        {commentsData.map(({ name, type, message, id, time }) =>
          type === 'anotherUserComment' ? (
            <li className={styles.another_user} key={id}>
              <AnotherUserComment name={name} time={time} message={message} />
            </li>
          ) : (
            <li className={styles.user} key={id}>
              <UserComment time={time} message={message} />
            </li>
          )
        )}
      </ul>
    </div>
  );
};
