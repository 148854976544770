import { Button } from 'shared/ui/Button';

import styles from './WithdrawFromBalanceBtn.module.scss';
export const WithrawFromBalanceBtn = () => {
  return (
    <Button type={3} additionalClasses={styles.btn}>
      {'Списать с депозита'}
    </Button>
  );
};
