import { CatalogType } from 'shared/types/Catalog/CatalogType';
import { ReactComponent as Add } from 'shared/assets/icons/addsquare.svg';
import { ItemsList } from 'shared/ui/ItemsList';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/store';
import { getAllQuestionnaires } from 'entities/questionnaires/api';
import { generateQueryString } from 'shared/helpers/generateQueryString';

import { CatalogQuestionnairesWidgetPropsI } from './CatalogQuestionnairesWidget.props';

const catalogs: Array<CatalogType> = [
  {
    id: 2,
    classname: 'lemon_chiffon',
    title: {
      ru: 'Exclusive + VIP',
      en: 'Exclusive + VIP',
      de: '',
    },
  },
  // {
  //   id: 3,
  //   classname: 'lemon_chiffon',
  //   title: {
  //     ru: 'Exclusive + VIP',
  //     en: 'Exclusive + VIP',
  //     de: '',
  //   },
  // },
  // {
  //   id: 4,
  //   classname: 'lemon_chiffon',
  //   title: {
  //     ru: 'Exclusive + VIP',
  //     en: 'Exclusive + VIP',
  //     de: '',
  //   },
  // },
  // {
  //   id: 5,
  //   classname: 'lemon_chiffon',
  //   title: {
  //     ru: 'Exclusive + VIP',
  //     en: 'Exclusive + VIP',
  //     de: '',
  //   },
  // },
];
export const CatalogQuestionnairesWidget = ({ id }: CatalogQuestionnairesWidgetPropsI) => {
  // let emptyFields = [];
  // if (catalogs.length < 3) {
  //   for (let i = 0; i < 3 - catalogs.length; i++) {
  //     emptyFields.push(<div className="filler-block" key={i}></div>);
  //   }
  // }

  const dispatch = useAppDispatch();

  const qParams = {
    page: 1,
    limit: 10,
    catalogIds: [id],
    popular: true,
  };

  useEffect(() => {
    if (id) {
      dispatch(getAllQuestionnaires(generateQueryString(qParams)));
    }
  }, []);

  return (
    <ItemsList
      list={catalogs}
      btnText={
        <>
          <Add className="svg_stroke mr-12" /> Добавить анкету
        </>
      }
      btnHandler={() => {}}
      type="CatalogType"
    />
  );
};
