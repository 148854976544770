import classNames from 'classnames';
import { useEffect, useState } from 'react';

import styles from './Checkbox.module.scss';
import { CheckboxPropsType } from './Checkbox.props';
import { ReactComponent as CheckboxIcon } from '../../assets/ui/ticksquare.svg';
export const Checkbox = ({ checked, onClick, disabled, className }: CheckboxPropsType) => {
  return (
    <CheckboxIcon
      className={classNames(styles.chekbox, checked && styles.checked, className)}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    />
  );
};
