import { Checkbox } from 'shared/ui/Checkbox';

import styles from './Stipulation.module.scss';

export interface StipulationPropsI {
  qParams: { text: string; stipulation: { buyed: boolean }; name: string; period: string };
  setQparams: any;
  disabled: boolean;
}

export const Stipulation = ({ qParams, setQparams, disabled }: StipulationPropsI) => {
  const stipulationArray = qParams?.stipulation
    ? (Object.keys(qParams?.stipulation) as Array<keyof typeof qParams.stipulation>)
    : [];

  console.log(qParams);

  const DetailsCheckboxText = {
    buyed: 'При оплате',
    catalog: 'При каталоге',
  };

  const handleChangeCheckbox = (key: keyof typeof qParams.stipulation) => {
    setQparams({
      ...qParams,
      stipulation: {
        ...qParams.stipulation,
        [key]: !qParams.stipulation[key],
      },
    });
  };
  return (
    <ul className={styles.checkbox_list}>
      {stipulationArray.map((key) => (
        <li key={key} className={styles.checkbox_item}>
          <Checkbox
            disabled={disabled}
            checked={qParams.stipulation[key]}
            onClick={() => handleChangeCheckbox(key)}
          />
          <p className={styles.name}>{DetailsCheckboxText[key]}</p>
        </li>
      ))}
    </ul>
  );
};
