import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import { useToast } from 'shared/hooks/useToast';

import styles from './Toast.module.scss';
import { ToastItem } from './components';

export const Toast = () => {
  const [isDocument, setIsDocument] = useState(false);

  const { toast } = useToast();

  useEffect(() => {
    if (toast.length) {
      setIsDocument(true);
    } else {
      setIsDocument(false);
    }
  }, [toast.length]);

  if (isDocument) {
    return ReactDOM.createPortal(
      <ul className={`${styles.container}`}>
        {toast.map(({ message, variant }) => (
          <ToastItem key={message} message={message} />
        ))}
      </ul>,
      document.body
    );
  } else {
    return null;
  }
};
