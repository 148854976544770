import { memo, ReactElement } from 'react';
// import { CatalogType } from 'shared/types/Catalog/CatalogType';
import { QuestionnairyItem } from 'entities/questionnaires/ui/QuestionnairesItem';
import { Avatar } from 'shared/types/Avatar/AvatarType';

import styles from './ItemsList.module.scss';
type ItemsListPropsT = {
  btnText: string | ReactElement;
  btnHandler: () => void;
  list: Array<any>;
  type: 'CatalogType' | 'yo';
};
const avatars: Array<Avatar> = [
  {
    id: 1,
    type: 'avatar',
    file: {
      id: 1,
      type: 'image',
      full: '9427e04d-428b-4dde-b48b-f056c2cdec14-5cb548ac-c9b0-4a0a-a9fe-47c6d19847bf.jpg',
      preview: '326f21fd-26c3-45de-9348-bfce78bc0be7-e6185abf-72e4-4bdf-aa54-8a178d4d5a74.jpg',
      fullBlur: '777a6f90-9508-45ca-b76b-29f17aab2bf0-8038aa11-7344-44ab-a10e-ac873552f2d3.jpg',
      previewBlur: '465c9048-f8fc-4336-9967-b4d6299d4d54-afc7df85-bd1c-4ad6-a9a8-761cd0315b6e.jpg',
    },
  },
  {
    id: 2,
    type: 'avatar',
    file: {
      id: 2,
      type: 'image',
      full: 'b33d45a3-7142-4685-808a-836ae1451f42-055829bb-50e3-4966-8bf5-8e0907695f9c.jpg',
      preview: '768dff29-6f95-4c78-bc95-b9132af7e82e-06448316-f160-4656-a0cc-c92bd765fd38.jpg',
      fullBlur: '72e552fe-2d6a-411b-85c1-9f9b2f17a0bb-0d9bf231-7838-4527-8ddf-57d38b14e096.jpg',
      previewBlur: '70744e90-3519-4bbe-8741-55e6355830e4-b8e772b5-20e9-4a9e-b4e1-fb6539621249.jpg',
    },
  },
  {
    id: 3,
    type: 'avatar',
    file: {
      id: 3,
      type: 'image',
      full: 'ec8043ff-140a-4693-9121-ea5a18111007-6dadf6f7-1a33-41d8-8796-0bf87e327e56.jpg',
      preview: 'dd25a9ad-34db-44b1-a116-7c0c2777a43c-e3645f77-4b68-47b4-b4a9-bc146842dcbb.jpg',
      fullBlur: 'b3c96ee4-d809-41be-a479-5abd34275a5f-fa8201c0-9955-4593-8134-66d6b6a2a3d2.jpg',
      previewBlur: 'ffab0184-bb61-4d37-8986-c30e116263b7-f0651757-5c3f-469b-bc0d-04ab08a59011.jpg',
    },
  },
];
export const ItemsList = ({ btnText, btnHandler, list, type }: ItemsListPropsT) => {
  let emptyFields = [];
  if (list.length < 3) {
    for (let i = 0; i < 3 - list.length; i++) {
      emptyFields.push(<div className="filler-block" key={i}></div>);
    }
  }
  return (
    <div className={styles.list}>
      <div className={styles.btn} onClick={btnHandler}>
        {btnText}
      </div>
      {list.map((i, index) => {
        if (type === 'CatalogType') {
          return (
            <QuestionnairyItem
              key={i.id}
              age={24}
              catalog={i}
              firstName="София"
              id={i.id}
              isBuyed={true}
              avatars={avatars}
            />
          );
        } else {
          return <div key={index}>yo</div>;
        }
      })}
      {emptyFields}
    </div>
  );
};
