import { Modal } from 'shared/ui/Modal';
import { Input } from 'shared/ui/Input';
import { useState } from 'react';
import { Checkbox } from 'shared/ui/Checkbox';
import { Button } from 'shared/ui/Button';

import { ChangePriceModalPropsI } from './ChangePriceModal.props';
import styles from './ChangePriceModal.module.scss';

export const ChangePriceModal = ({ isVisible, close }: ChangePriceModalPropsI) => {
  const [newPrice, setNewPrice] = useState('');
  const [isChekedAllContent, setIsChekedAllContent] = useState(false);

  return (
    <Modal isVisible={isVisible} close={close}>
      <p className={styles.title}>Изменить цену</p>
      <div className={styles.input_container}>
        <Input
          additionalClasses={styles.input}
          tip={'Назначьте цену'}
          tipAsPlaceholder={true}
          value={newPrice}
          valueType="number"
          setValue={setNewPrice}
          type={3}
        />
        <span>$</span>
      </div>
      <div className={styles.checkbox_container}>
        <Checkbox
          className={styles.checkbox}
          checked={isChekedAllContent}
          onClick={() => setIsChekedAllContent((prev) => !prev)}
        />
        <span className={styles.checkbox_text}>Применить ко всем фото/видео</span>
      </div>
      <div className={styles.buttons_container}>
        <Button type={4}>Отменить</Button>
        <Button type={3}>Сохранить</Button>
      </div>
    </Modal>
  );
};
