import { OrderCardPropsI } from './OrderCard.props';
import styles from './OrderCard.module.scss';
import { Badge } from '../../../../shared/ui/Badge';
import { getFormatedDate } from '../../../../shared/helpers/getFormatedDate/getFormatedDate';
import { OrderProgressBar } from './components/OrderProgressBar';
import { Button } from '../../../../shared/ui/Button';

export const OrderCard = ({
  age,
  name,
  city,
  classname,
  country,
  status,
  title,
  image,
  date,
}: OrderCardPropsI) => {
  return (
    <div className={styles.container}>
      <img src={image} alt="girl_photo" />

      <div className={styles.main_informations}>
        <span className={styles.name_text}>
          {name}, {age}
        </span>
        <Badge
          additionalClasses={styles.badge}
          type={classname}
          value={title.toLocaleUpperCase()}
        />
        <div className={styles.location}>
          <span className={styles.location_text}>
            {city}, {country}
          </span>
          <div className={styles.date}>
            <p className={styles.date_text}>{getFormatedDate(date)}</p>
          </div>
          <OrderProgressBar status={status} />
          <Button additionalClasses={styles.button} type={3}>
            Перейти в каталог
          </Button>
        </div>
      </div>
    </div>
  );
};
