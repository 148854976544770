import { Link } from 'react-router-dom';

import styles from './Footer.module.scss';

export const Footer = () => {
  const fullYear = new Date().getFullYear();
  return (
    <footer className={styles.footer}>
      <p className={styles.yellow_text}>REAL.TOP {fullYear}</p>
      <div className={styles.right_content}>
        <Link className={styles.link} to={''}>
          Идеи и предложения по работе сервиса
        </Link>
        <div className={styles.additional}>
          <Link className={styles.link} to={''}>
            Конфидециальность
          </Link>
          <Link className={styles.link} to={''}>
            Правила
          </Link>
          <Link className={styles.link} to={''}>
            Помощь
          </Link>
        </div>
      </div>
    </footer>
  );
};
