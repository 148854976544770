import { useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { useOutsideClick } from 'shared/hooks/useOutsideClick/useOutsideClick';

import { DropDownButtonsProps, PositionType } from './DropDownButtons.props';
import styles from './DropDownButtons.module.scss';

const DEFAULT_PADDING_TOP = 20;
const DEFAULT_PADDING_LEFT = 20;

export const DropDownButtons = ({
  buttons,
  className,
  isVisible,
  rectValue,
  setIsVisible,
}: DropDownButtonsProps) => {
  const [position, setPosition] = useState<PositionType>({
    top: 0,
    left: 0,
  });
  const refList = useRef<HTMLUListElement | null>(null);

  useLayoutEffect(() => {
    if (refList.current && isVisible && rectValue) {
      const listRect = refList.current.getBoundingClientRect();
      setPosition({
        top: rectValue.top + rectValue.height + DEFAULT_PADDING_TOP,
        left: rectValue.left - listRect.width + DEFAULT_PADDING_LEFT,
      });
    }
  }, [isVisible, rectValue]);

  // useLayoutEffect(() => {
  //   updatePosition();

  //   window.addEventListener('resize', updatePosition);

  //   return () => {
  //     window.removeEventListener('resize', updatePosition);
  //   };
  // }, [updatePosition, isVisible, positions]);

  useOutsideClick(refList, () => setIsVisible(false), isVisible);

  console.log(refList.current?.getBoundingClientRect());

  return isVisible
    ? createPortal(
        <ul
          ref={refList}
          style={{
            top: `${position.top}px`,
            left: `${position.left}px`,
          }}
          className={classNames(styles.list, className)}
        >
          {buttons.map(({ id, name, onClick }) => (
            <li key={id}>
              <button onClick={onClick} className={styles.button}>
                {name}
              </button>
            </li>
          ))}
        </ul>,
        document.body
      )
    : null;
};
