import { useEffect, useState } from 'react';
import { useToast } from 'shared/hooks/useToast';
import { ReactComponent as CrossIcon } from 'shared/assets/icons/closesquare.svg';

import styles from './ToastItem.module.scss';
import { ToastItemT } from './ToastItem.props';

export const ToastItem = ({ message }: ToastItemT) => {
  const [isVisibleMessage, setIsVisibleMessage] = useState(false);
  const [isRunningTimeout, setIsRunningTimeout] = useState(true);
  const [stopTime, setStopTime] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(4000);
  const [width, setWidth] = useState(100);

  const { clearToast } = useToast();

  const handleOnMouseEnter = () => {
    setIsRunningTimeout(false);
  };

  const handleOnMouseLeave = () => {
    setIsRunningTimeout(true);
  };

  ///// элемент появляется

  useEffect(() => {
    let showTimer: NodeJS.Timeout;
    showTimer = setTimeout(() => {
      setIsVisibleMessage(true);
    }, 100);

    return () => {
      clearTimeout(showTimer);
    };
  }, []);

  ///// ставится время остановки и время старта

  useEffect(() => {
    if (!isRunningTimeout) {
      setStopTime(Date.now());
    } else {
      setStartTime(Date.now());
    }
  }, [isRunningTimeout]);

  ///// тут происходит скрытие (1-ый setTimeout) и удаление сообщения из списка (2-ой setTimeout)

  useEffect(() => {
    let hideTimer: NodeJS.Timeout;
    let clearTimer: NodeJS.Timeout;

    if (!isRunningTimeout) {
      return;
    }

    const elapsedTime = stopTime && stopTime - startTime;
    const remainingTime = currentTime - elapsedTime;
    setCurrentTime(remainingTime);

    hideTimer = setTimeout(() => {
      setIsVisibleMessage(false);
    }, remainingTime);

    clearTimer = setTimeout(() => {
      clearToast(message);
    }, remainingTime + 1000);

    return () => {
      clearTimeout(hideTimer);
      clearTimeout(clearTimer);
    };
  }, [isRunningTimeout, stopTime]);

  ///// интервал для прогресс бара

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!isRunningTimeout) {
      return;
    }

    timer = setInterval(() => {
      setWidth((prevWidth) => prevWidth - 1);
    }, 40);

    return () => {
      clearInterval(timer);
    };
  }, [isRunningTimeout]);

  return (
    <li
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      className={`${styles.toast} ${isVisibleMessage ? styles.show : styles.hide}`}
    >
      <button onClick={() => setIsVisibleMessage(false)} className={styles.button}>
        <CrossIcon />
      </button>
      <p className={styles.message}>{message}</p>
      <div style={{ width: `${width}%` }} className={styles.proggres_bar}></div>
    </li>
  );
};
