export enum ToastVariants {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export type Toast = {
  message: string;
  variant: ToastVariants;
};

export type ToastStore = {
  toast: Toast;
  clearToast: () => void;
  showToast: (toastData: Toast, clearToast?: number) => void;
};
