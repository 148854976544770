import { useState } from 'react';

import styles from './Comments.module.scss';
import { UserType } from './CommentsView/CommentsView.props';
import { CommentsShowButton } from './CommentsShowButton/CommentsShowButton';
import { CommentsView } from './CommentsView/CommentsView';
import { CommentInput } from './CommentInput/CommentInput';

export const Comments = () => {
  const [isVisible, setIsVisible] = useState(false);

  const mockData = [
    {
      type: UserType.Another,
      message: 'Lorem ipsum dolor sit amet consectetur.Risus dictum quam pretium non congue.',
      name: 'Totoroooooo058',
      id: 1,
      time: '15:36',
    },
    { type: UserType.User, message: 'Lorem ipsum dolor sit amet', id: 2, time: '15:36' },
    {
      type: UserType.Another,
      message:
        'Lorem ipsum dolor sit amet consectetur. Non id sed eget mauris tincidunt metus sed. Pellentesque commodo turpis pellentesque cursus consectetur enim fermentum etiam. Sit commodo cras ut vitae et condimentum. Et lorem neque nulla ut aliquet. Etiam tortor purus in duis bibendum nisl aenean vestibulum aliquam. Pellentesque at nulla bibendum praesent enim.',
      name: 'JackTapok',
      id: 3,
      time: '15:36',
    },
    {
      type: UserType.Another,
      message: 'Lorem ipsum dolor sit amet',
      name: 'JackTapok',
      id: 4,
      time: '15:36',
    },
    {
      type: UserType.User,
      message:
        'Lorem ipsum dolor sit amet consectetur. Non id sed eget mauris tincidunt metus sed. Pellentesque commodo turpis pellentesque cursus consectetur enim fermentum etiam. Sit commodo cras ut vitae et condimentum. Et lorem neque nulla ut aliquet. Etiam tortor purus in duis bibendum nisl aenean vestibulum aliquam. Pellentesque at nulla bibendum praesent enim.',
      id: 5,
      time: '15:36',
    },
  ];

  const [commentsData, setCommentsData] = useState(mockData);

  const handleClickShowComments = () => {
    setIsVisible((prev) => !prev);
  };

  return (
    <>
      <CommentsShowButton handleClickShowComments={handleClickShowComments} isVisible={isVisible} />
      {isVisible && (
        <div className={styles.container}>
          <CommentsView commentsData={commentsData} />
          <CommentInput setCommentsData={setCommentsData} />
        </div>
      )}
    </>
  );
};
