import { IMAGES_URL } from 'shared/config/urls';
import { Badge } from 'shared/ui/Badge';
import { Link } from 'react-router-dom';

import { QuestionnairyItemV2PropsT } from './QuestionnairyItemV2PropsT';
import styles from './QuestionnaireItemV2.module.scss';

export const QuestionnairyItemV2 = ({
  age,
  avatar,
  catalogName,
  city,
  classname,
  country,
  name,
  id,
}: QuestionnairyItemV2PropsT) => {
  // const { locale = 'ru', push } = useRouter();

  return (
    <div
      className={styles.card}
      style={{ backgroundImage: `url(${IMAGES_URL}${avatar.file.preview})` }}
    >
      <div
        className={styles.wrapper}
        // onClick={() => {
        //   push(`#${id}`);
        // }}
      >
        <div className="mt-a">
          <Badge value={catalogName['ru']} type={classname} />
          <p className={styles.name}>
            <Link to={`#${id}`}>
              {name}, {age}
            </Link>
          </p>
          <p className={styles.country}>
            {country['ru']}, {city['ru']}
          </p>
        </div>
      </div>
      {/* <Image
        className={styles.img}
        src={`${IMAGES_URL}${avatar.file.preview}`}
        alt="girl photo"
        width={width || 232}
        height={height || 232}
      /> */}
    </div>
  );
};
