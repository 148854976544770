import { useState } from 'react';
import { Input } from 'shared/ui/Input';
import { ReactComponent as SearchSVG } from 'shared/assets/icons/search.svg';

import styles from './ChatSearch.module.scss';
export const ChatSearch = () => {
  const [value, setValue] = useState('');
  return (
    <div className={styles.box}>
      {/* <div className={styles.input}></div> */}
      <Input
        type={1}
        placeholder={'Поиск'}
        value={value}
        setValue={setValue}
        additionalClasses={styles.input}
        iconRight={<SearchSVG />}
      />
    </div>
  );
};
