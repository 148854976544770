import { useAppDispatch, useAppSelector } from 'app/store';
import { useEffect } from 'react';
import { getAllAuctions } from 'entities/auctions/api';

import { AuctionsList } from '../../../../widgets/Auctions/AuctionsList';

export const AllAuctions = () => {
  const { auctions } = useAppSelector((state) => state.auctions);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllAuctions(false));
  }, []);

  return <AuctionsList auctions={auctions} isAuctionPage={true} />;
};
