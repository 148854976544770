import { getFormatedDate } from 'shared/helpers/dateFunctions';

import { ReferalsListItemProps } from './ReferalsListItem.props';
import styles from './ReferalsListItem.module.scss';
import { Сell } from '../../../shared/ui/Сell';

export const ReferalsListItem = ({ name, bonus, sum, registerDate }: ReferalsListItemProps) => {
  return (
    <li className={styles.item}>
      <p className={styles.name}>{name}</p>
      <Сell text="Дата регестрации" value={getFormatedDate(String(registerDate))} />
      <Сell text="Сумма пополнения" value={`${sum.toLocaleString('ru-RU')}$`} />
      <Сell text="Бонус за реферала" value={`+${bonus}$`} />
    </li>
  );
};
