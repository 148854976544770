import { useForm } from 'shared/hooks/useForm/useForm';
import { FormType } from 'shared/types/FormType/FormType';
import { Button } from 'shared/ui/Button';
import { CreateForm } from 'widgets/CreateForm';
import { CalendarCustom } from 'shared/ui/Calendar';
import { useState } from 'react';
import { Banner } from 'widgets/Questionnaires/Banner';
import { ImageContainer } from 'widgets/Questionnaires/ImageContainer';
import { ChangeForm } from 'widgets/Questionnaires/ChangeForm';
import { Tabs } from 'shared/ui/Tabs';
import { YouDontSaveModal } from 'widgets/Questionnaires/YouDontSaveModal';
import { Photos } from 'widgets/Questionnaires/Photos';
import { Video } from 'widgets/Questionnaires/Video';
import { Hot } from 'widgets/Questionnaires/Hot';

import styles from './CreateQuestionnaire.module.scss';

const CreateQuestionnaire = () => {
  type ValuePiece = Date | null;

  type Value = ValuePiece | [ValuePiece, ValuePiece];
  const [value, onChange] = useState<Value>();
  const [value2, onChange2] = useState<Value>();

  //@ts-ignore
  const finishDate = value2?.toISOString().split('T')[0];
  const currentDate = new Date().toISOString().split('T')[0];

  const tabs = [
    {
      content: <Photos />,
      label: 'Фото',
      id: 0,
    },
    {
      content: <Video />,
      label: 'Видео',
      id: 1,
    },
    {
      content: <Hot />,
      label: 'HOT 18+',
      isHot: true,
      id: 2,
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <Banner title="Создать анкету" buttonText="Опубликовать" />
      <div className={styles.content}>
        <ImageContainer />
        <div className={styles.info_container}>
          <ChangeForm />
          <Tabs className={[styles.tabs]} tabs={tabs} />
        </div>
      </div>
    </div>
  );
};

export default CreateQuestionnaire;
