import classNames from 'classnames';

import { getFormatedDate } from '../../../../../../shared/helpers/getFormatedDate/getFormatedDate';
import styles from './Transctions.module.scss';
import { TransctionsPropsI } from './Transctions.props';

export const Transctions = ({ operationsList, className }: TransctionsPropsI) => {
  return (
    <ul className={classNames(styles.transaction_list, className && className)}>
      {operationsList.map(({ date, time, sum, id }) => (
        <li key={id} className={styles.transaction_item}>
          <span className={styles.transaction_date}>{getFormatedDate(date)}</span>
          <span className={styles.transaction_date}> {time}</span>
          <span className={styles.transaction_sum}>+ {sum}$</span>
        </li>
      ))}
    </ul>
  );
};
