import { Select } from '../../shared/ui/Select';
import styles from './SelectFilters.module.scss';
import { SelectFiltersPropsI } from './SelectFilters.props';

export const SelectFilters = ({ detailsSelect }: SelectFiltersPropsI) => {
  return (
    <ul
      style={{ gridTemplateColumns: `repeat(${detailsSelect.length}, 1fr)` }}
      className={`${styles.selects}`}
    >
      {detailsSelect.map(({ options, currentOption, placeholder, onClickItem }) => {
        return (
          <li className={styles.item} key={placeholder}>
            <Select
              className={styles.select}
              options={options}
              placeholder={placeholder}
              currentOption={currentOption}
              onClickItem={onClickItem}
            />
          </li>
        );
      })}
    </ul>
  );
};
