import styles from './OrderProgressBar.module.scss';
import { OrderProgressBarProps } from './OrderProgressBar.props';

export const OrderProgressBar = ({ status }: OrderProgressBarProps) => {
  const DetailsStatus: { [key: string]: string } = {
    communication: '25%',
    payment: '50%',
    meeting: '75%',
    completion: '100%',
  };

  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        <li className={styles.item}>Общение</li>
        <li className={styles.item}>Оплата</li>
        <li className={styles.item}>Встреча</li>
        <li className={styles.item}>Завершение</li>
      </ul>
      <div style={{ width: DetailsStatus[status] }} className={styles.bar}></div>
    </div>
  );
};
