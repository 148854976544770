import { useRef, useState } from 'react';
import { BUTTONS_ON_KEYBOARD } from 'shared/constans/buttonOnKeyboard/buttonOnKeyboard';
import { convertISODateToTime } from 'shared/helpers/dateFunctions';
import { ReactComponent as SendSVG } from 'shared/assets/ui/EnterMessageBlock/send.svg';

import styles from './CommentInput.module.scss';
import { CommentsViewPropsI, UserType } from '../CommentsView/CommentsView.props';

export const CommentInput = ({ setCommentsData }: any) => {
  const [value, setValue] = useState('');

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleCreateMessage = () => {
    const newMessage = {
      message: value,
      time: convertISODateToTime(new Date().toISOString()),
      id: Date.now(),
      type: UserType.User,
    };
    if (value && textareaRef.current) {
      setCommentsData((prev: Array<CommentsViewPropsI>) => [...prev, newMessage]);
      textareaRef.current.style.height = 'auto';
    }
    setValue('');
  };

  //   useKeyPress(handleCreateMessage, BUTTONS_ON_KEYBOARD.Enter);

  return (
    <div className={styles.container}>
      <textarea
        ref={textareaRef}
        className={styles.textarea}
        value={value}
        placeholder="Написать комментарий..."
        onChange={handleChange}
      ></textarea>
      <button disabled={!value} onClick={handleCreateMessage}>
        <SendSVG />
      </button>
    </div>
  );
};
