import styles from './Badge.module.scss';
import { BadgeProps } from './Badge.props';

export const Badge = ({ value, additionalClasses, type }: BadgeProps) => {
  return (
    <span className={`${styles.badge} ${additionalClasses} ${styles['badge-' + type]}`}>
      {value}
    </span>
  );
};
