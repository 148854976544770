import { OrderCard } from '../../../entities/orders/ui/OrderCard';
import { OrderCardListpPropsI } from './OrderCardList.props';
import styles from './OrderCardList.module.scss';

export const OrderCardList = ({ detailsOrders }: OrderCardListpPropsI) => {
  return (
    <div className={styles.list}>
      {detailsOrders.map(
        ({ name, age, classname, city, country, status, title, image, date, id }) => (
          <OrderCard
            key={id}
            id={id}
            name={name}
            age={age}
            classname={classname}
            title={title}
            image={image}
            status={status}
            city={city}
            country={country}
            date={date}
          />
        )
      )}
    </div>
  );
};
