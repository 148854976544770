import { AuctionCard } from '../../../entities/auctions/ui/AuctionCard/AuctionCard';
import styles from './AuctionsList.module.scss';
import { AuctionsListPropsI } from './AuctionsList.props';

export const AuctionsList = ({
  auctions,
  isAuctionPage = false,
  isClosedTab = false,
}: AuctionsListPropsI) => {
  return (
    <ul className={styles.list}>
      {auctions.map(
        ({
          id,
          description,
          initialBet,
          name,
          startDate,
          endDate,
          close,
          photos,
          fullRedemption,
        }) => (
          <li key={id}>
            <AuctionCard
              close
              fullRedemption={fullRedemption}
              photos={photos}
              startDate={startDate}
              endDate={endDate}
              isAuctionsPage={isAuctionPage}
              initialBet={initialBet}
              name={name}
              isClosedTab={isClosedTab}
              description={description}
              id={id}
            />
          </li>
        )
      )}
    </ul>
  );
};
