import { Button } from 'shared/ui/Button';
import ROUTES from 'shared/constans/routes';

import { ReactComponent as AddSquareIcon } from '../../shared/assets/icons/addsquare.svg';
import { Tabs } from '../../shared/ui/Tabs';
import styles from './Catalogs.module.scss';
import { AllCatalogsTab } from './Tabs/AllCatalogsTab';
import { QuestionnaireTab } from './Tabs/QuestionnaireTab';
const Catalogs = () => {
  const tabs = [
    {
      content: <AllCatalogsTab />,
      label: 'Все каталоги',
      id: 0,
    },
    {
      content: <QuestionnaireTab />,
      label: 'Анкеты',
      id: 1,
    },
  ];

  return (
    <div style={{ flexGrow: 1 }}>
      <div className={`${styles.head} block`}>
        <span className={styles.title}>Каталоги</span>
        <div className={styles.btns}>
          <Button
            additionalClasses={styles.button}
            type={4}
            tag="link"
            href={ROUTES.CREATE_QUESTIONNAIRE}
          >
            <AddSquareIcon className="mr-15 svg_stroke" /> Создать анкету
          </Button>
          <Button
            additionalClasses={styles.button}
            type={4}
            tag="link"
            href={ROUTES.CREATE_CATALOG}
          >
            <AddSquareIcon className="mr-15 svg_stroke" /> Создать каталог
          </Button>
        </div>
      </div>
      <Tabs tabs={tabs} />
    </div>
  );
};

export default Catalogs;
