// import { useTranslation } from 'next-i18next';

// import { useAppDispatch, useAppSelector } from '@/app/store/hooks';
// import { changeSubscription } from '@/entities/subscriptions/api';
import { Button } from 'shared/ui/Button';

export const UnsubscribeBtn = ({
  subscriptionId,
  close,
}: {
  subscriptionId: number;
  close: () => void;
}) => {
  // const { t } = useTranslation('subscription');
  // const dispatch = useAppDispatch();
  // const { subscriptionsLoading } = useAppSelector((state) => state.subscriptions);
  // const changeSubscriptionFn = () => {
  //   dispatch(changeSubscription({ subscriptionId, subscribed: false }))
  //     .unwrap()
  //     .then(() => close());
  // };
  return (
    <Button
      type={4}
      //  disabled={subscriptionsLoading}
      // onClick={changeSubscriptionFn}
    >
      Да, хочу отменить
    </Button>
  );
};
