export const processImage = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const image = new Image();
      image.src = reader.result as string;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = 500;
        canvas.height = 500;
        context?.drawImage(image, 0, 0, 500, 500);
        const croppedImage = canvas.toDataURL('image/jpeg');
        resolve(croppedImage);
      };
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};
