import React, { useState } from 'react';
import { Badge } from 'shared/ui/Badge';

import styles from './Emotions.module.scss';
import { EmotionsProps } from './Emotions.props';

export const Emotions = ({ className, emotions }: EmotionsProps) => {
  const list = [
    { icon: '😘', name: 'kiss', id: 1 },
    { icon: '😛', name: 'smile', id: 2 },
    { icon: '❤️', name: 'heart', id: 3 },
  ];

  const [activeEmotion, setActiveEmotion] = useState<number | null>(null);

  const sortedList = list.sort(
    (a, b) => emotions[b.name as keyof typeof emotions] - emotions[a.name as keyof typeof emotions]
  );

  const handleButtonClick = (id: number) => {
    setActiveEmotion(id);
  };

  return (
    <ul className={`${styles.list} ${className}`}>
      {sortedList.map(({ id, icon, name }) => (
        <li key={id} className={styles.item}>
          <button onClick={() => handleButtonClick(id)}>
            <Badge
              additionalClasses={`${styles.badge} ${activeEmotion === id ? styles.active : ''}`}
              value={
                icon +
                (activeEmotion === id
                  ? emotions[name as keyof typeof emotions] + 1
                  : emotions[name as keyof typeof emotions])
              }
            />
          </button>
        </li>
      ))}
    </ul>
  );
};
