import { ReactNode, createContext, useState } from 'react';

import { Toast } from './interfaces';

export interface ToastContextInterface {
  toast: Toast[];
  showToast: (toast: Toast) => void;
  clearToast: (message: string) => void;
}

const defaultValue = {
  toast: [],
  showToast: () => {},
  clearToast: () => {},
};

export const ToastContext = createContext<ToastContextInterface>(defaultValue);

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [toast, setToast] = useState<Toast[]>([]);

  const showToast = (toast: Toast) => {
    setToast((prevToast) => [...prevToast, toast]);
  };

  const clearToast = (message: string) => {
    setToast((prevToast) => prevToast.filter(({ message: msg }) => msg !== message));
  };

  const value = {
    toast,
    showToast,
    clearToast,
  };

  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>;
};
