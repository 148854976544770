import { useState } from 'react';
import { Badge } from 'shared/ui/Badge';

import styles from './NewsNavigations.module.scss';
import { SubcategoryList } from './SubcategoryList/SubcategoryList';

export const NewsNavigations = () => {
  const [selectedCategory, setSelectedCategory] = useState(1);

  const handleClickChangeCategory = (id: number) => {
    setSelectedCategory(id);
  };

  const mockData = [
    {
      name: 'Новости',
      id: 1,
      subcategory: [
        {
          name: 'Подкатегория1',
          id: 11,
        },
        {
          name: 'Подкатегория2',
          id: 10,
        },
      ],
      notification: 2,
    },
    {
      name: 'Подписки',
      id: 2,
      subcategory: [
        {
          name: 'Подкатегория1',
          id: 6,
        },
        {
          name: 'Подкатегория2',
          id: 7,
        },
      ],
      notification: 1,
    },
    {
      name: 'Избранное',
      id: 3,
      subcategory: [
        {
          name: 'Подкатегория1',
          id: 4,
        },
        {
          name: 'Подкатегория2',
          id: 5,
        },
      ],
      notification: 0,
    },
  ];

  return (
    <div className={styles.container}>
      <ul className={styles.list_category}>
        {mockData.map(({ id, name, subcategory, notification }) => (
          <li key={id}>
            <button
              onClick={() => handleClickChangeCategory(id)}
              className={`${styles.text} ${selectedCategory === id && styles.selected_category}`}
            >
              {name} {notification ? <Badge value={notification} /> : null}
            </button>

            <SubcategoryList
              className={`${styles.subcategories_list} ${
                selectedCategory === id ? styles.visible : styles.hidden
              }`}
              subcategories={subcategory}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
