import { ReactComponent as ArrowIcon } from 'shared/assets/icons/arrow.svg';

import styles from './CommentsShowButton.module.scss';
import { CommentsShowButtonProps } from './CommentsShowButton.props';

export const CommentsShowButton = ({
  handleClickShowComments,
  isVisible,
}: CommentsShowButtonProps) => {
  return (
    <button onClick={handleClickShowComments} className={styles.comments_button}>
      13 комментариев
      <span className={styles.icon}>
        {<ArrowIcon className={`${isVisible ? styles.active : styles.arrow}`} />}
      </span>
    </button>
  );
};
