import { Modal } from 'shared/ui/Modal';
import { Button } from 'shared/ui/Button';

import { CloseAuctionModalPropsI } from './CloseAuctionModal.props';
import styles from './CloseAuctionModal.module.scss';

export const CloseAuctionModal = ({
  isVisible,
  closeModal,
  closeAuction,
}: CloseAuctionModalPropsI) => {
  return (
    <Modal isVisible={isVisible} close={closeModal}>
      <p className={styles.title}>Завершить аукцион сейчас?</p>
      <p className={styles.text}>Аукцион будет завершен и перемещен во вкладку “Завершенные”</p>
      <div className={styles.buttons_container}>
        <Button onClick={closeModal} type={4}>
          Отменить
        </Button>
        <Button onClick={closeAuction} type={3}>
          Да, завершить
        </Button>
      </div>
    </Modal>
  );
};
