import { memo } from 'react';

import styles from './UserComment.module.scss';
import { UserCommentPropsI } from './UserComment.props';

export const UserComment = memo(({ message, time }: UserCommentPropsI) => {
  return (
    <div className={styles.container}>
      <p className={styles.message}>{message}</p>
      <span className={styles.time}>{time}</span>
    </div>
  );
});

UserComment.displayName = 'UserComment';
