export const generateQueryString = (obj: Record<string, any>) => {
  let string = '';
  let paramsArr = [];
  for (let key in obj) {
    if (obj[key] !== null) {
      paramsArr.push(`${key}=${obj[key]}`);
    }
  }
  if (paramsArr.length) {
    paramsArr.forEach((p, i) => {
      string += `${i === 0 ? '?' : '&'}${p}`;
    });
  }
  return string;
};
