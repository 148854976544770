import { useForm } from 'shared/hooks/useForm/useForm';
import { FormType } from 'shared/types/FormType/FormType';
import { Button } from 'shared/ui/Button';
import { CatalogQuestionnairesWidget } from 'widgets/Catalogs/CatalogQuestionnairesWidget';
import { CatalogSelectsWidget } from 'widgets/Catalogs/CatalogSelectsWidget';
import { CatalogForm } from 'widgets/Catalogs/CatalogForm';
import { useCreateCatalogMutation } from 'entities/catalogs/api';
import { getFormatedDate } from 'shared/helpers/dateFunctions';

import styles from './CreateCatalogPage.module.scss';
export const CreateCatalogPage = () => {
  const initialForm: FormType = {
    title: {
      id: 1,
      isRequired: true,
      isValid: false,
      placeholder: 'Название',
      type: 'input',
      value: '',
      errorText: '',
    },
    subtitle: {
      id: 2,
      isRequired: true,
      isValid: false,
      placeholder: 'Подпись',
      type: 'input',
      value: '',
      errorText: '',
    },
    gradient: {
      id: 3,
      isRequired: true,
      isValid: false,
      placeholder: 'Выберите цвет каталога',
      type: 'select',
      value: '',
      currentOption: 1,
      errorText: '',
      selectOptions: [
        { id: 1, value: 'lemon' },
        { id: 2, value: 'chifon' },
      ],
    },
    startDate: {
      type: 'calendar',
      id: 0,
      isValid: false,
      placeholder: 'Запланировать публикацию',
      isRequired: true,
      value: '',
    },
    price: {
      type: 'input',
      id: 4,
      isValid: false,
      placeholder: 'Стоимость',
      isRequired: true,
      value: '7000',
      errorText: '',
    },
    description: {
      type: 'textarea',
      id: 5,
      placeholder: 'Описание каталога',
      isRequired: false,
      isValid: false,
      errorText: '',
      value: '',
    },
  };

  const { form, handleChangeForm } = useForm(initialForm);

  const submiteData = {
    titleRu: form['title'].value,
    descriptionRu: form['description'].value,
    subtitleRu: form['subtitle'].value,
    gradient: form['gradient'].value,
    price: form['price'].value,
    plannedDate: getFormatedDate(String(form['startDate'].value)),
  };

  const [createCatalog, { isLoading, isError }] = useCreateCatalogMutation();

  const handleCreateCatalog = () => {
    createCatalog(submiteData);
  };

  return (
    <div style={{ width: '100%' }}>
      <CatalogForm form={form} handleChangeForm={handleChangeForm} />
      <CatalogSelectsWidget />
      <CatalogQuestionnairesWidget />
      <Button onClick={handleCreateCatalog} type={3} additionalClasses={styles.btn}>
        Опубликовать
      </Button>
    </div>
  );
};
