import { useNavigate } from 'react-router-dom';
import { Input } from 'shared/ui/Input';
import { CalendarCustom } from 'shared/ui/Calendar';
import classNames from 'classnames';

import { Select } from '../../shared/ui/Select';
import styles from './CreateForm.module.scss';
import { DetailsFormElementsPropsI } from './CreateForm.props';
import { ReactComponent as BackButtonIcon } from '../../shared/assets/icons/arrowsquareleft.svg';

export const CreateForm = ({ form, title, handleChangeForm }: DetailsFormElementsPropsI) => {
  const navigate = useNavigate();

  const formKeys = Object.keys(form);

  return (
    <div className={styles.container}>
      <button onClick={() => navigate(-1)} className={styles.back_button}>
        <BackButtonIcon />
      </button>
      <div className={styles.content}>
        {title && <p className={styles.title}>{title}</p>}
        <ul className={styles.inputs_list}>
          {formKeys.map((key) =>
            form[key].type === 'input' ? (
              <li className={styles.inputs_item} key={form[key].id}>
                {/* <input
          placeholder={form[key].placeholder}
          className={styles.input}
          type="text"
          value={form[key].value}
          onChange={(event) => handleChangeForm(key, event.target.value)}
        /> */}
                <Input
                  // additionalClasses={styles.input}
                  type={2}
                  value={form[key].value || ''}
                  tip={form[key].placeholder}
                  tipAsPlaceholder={true}
                  setValue={(val) => handleChangeForm(key, val)}
                />
                {form[key].errorText && (
                  <span className={styles.valid_error}>{form[key].errorText}</span>
                )}
              </li>
            ) : form[key].type === 'select' ? (
              <li className={styles.inputs_item} key={form[key].id}>
                <Select
                  options={form[key].selectOptions || []}
                  v={2}
                  placeholder={''}
                  tip={form[key]?.placeholder}
                  tipAsPlaceholder={true}
                  currentOption={form[key].value || 0}
                  onClickItem={(id: number) => handleChangeForm(key, id)}
                />
              </li>
            ) : (
              form[key].type === 'calendar' && (
                <li className={classNames(styles.inputs_item)} key={form[key].id}>
                  <CalendarCustom
                    title={form[key].placeholder}
                    maxDate={
                      key === 'startDate' && form['endDate'].value
                        ? //@ts-ignore
                          form['endDate'].value?.toISOString().split('T')[0]
                        : ''
                    }
                    date={form[key].value}
                    setDate={(date: any) => handleChangeForm(key, date)}
                  />
                </li>
              )
            )
          )}
        </ul>
        <textarea
          key={form['description']?.id}
          onChange={(event) => handleChangeForm('description', event.target.value)}
          placeholder={form['description']?.placeholder}
          className={styles.textarea}
        />
      </div>
    </div>
  );
};
