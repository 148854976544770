// import { useAppDispatch } from 'app/store';
// import { sendMessage } from 'entities/chats/model';
import { useEffect, useRef, useState } from 'react';
import { SendMessageBtn } from 'features/chat/SendMessageBtn';
// import AttachSvg from 'shared/assets/ui/EnterMessageBlock/attachsquare.svg';
// import MicroSvg from 'shared/assets/ui/EnterMessageBlock/microphone.svg';

import styles from './EnterMessageBlock.module.scss';

type EnterMessageBlockT = {
  chatRoomId?: number | string;
};
export const EnterMessageBlock = ({ chatRoomId }: EnterMessageBlockT) => {
  const [value, setValue] = useState('');
  // const dispatch = useAppDispatch();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleSendMessage = () => {
    if (value && value.trim()) {
      // dispatch(
      //   sendMessage({
      //     chatRoomId,
      //     newMessage: {
      //       id: +new Date(),
      //       from: 'me',
      //       message: value,
      //       time: new Date().toISOString(),
      //       type: 'message',
      //     },
      //   })
      // );
      setValue('');
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '20px';
      if (textareaRef.current.scrollHeight < 61) {
        textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
      } else {
        textareaRef.current.style.height = '60px';
      }
    }
  }, [value]);
  return (
    <div style={{ width: '100%' }} className="mt-a">
      <div className={styles.block}>
        {/* <AttachSvg /> */}
        <textarea
          onKeyDown={(e) => {
            if (e.code === 'Enter' && e.shiftKey === false) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          ref={textareaRef}
          placeholder={'Написать сообщение...'}
          className={`${styles.textarea} scroll`}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        ></textarea>
        {value && value.trim() ? <SendMessageBtn handler={handleSendMessage} /> : ''}
      </div>
    </div>
  );
};
