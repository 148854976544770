import { Fragment, useEffect, useRef } from 'react';
import { ChatT } from 'entities/chats/types';

import styles from './ChatWindow.module.scss';
import { ChatMessage } from './Components/ChatMessage';
import { DateBlock } from './Components/DateBlock';
import { ChatMoney } from './Components/ChatMoney/ChatMoney';
import { RatingMessage } from './Components/RatingMessage';
import { DonateMessage } from './Components/DonateMessage';
import { ContactRequestMessage } from './Components/ContactRequestMessage';
import { ThanksForDonationMessage } from './Components/ThanksForDonationMessage';
import { ContactMessage } from './Components/ContactMessage';
import { SelectionMessage } from './Components/SelectionMessage';

export const ChatWindow = ({ chat, name }: { chat: ChatT | undefined; name: string | null }) => {
  const chatWindowRef = useRef<HTMLDivElement>(null);
  const shouldScrollRef = useRef(true);
  useEffect(() => {
    shouldScrollRef.current = true;
  }, [name]);
  useEffect(() => {
    if (chatWindowRef.current) {
      const scrollEndHandler = () => {
        if (
          chatWindowRef.current &&
          chatWindowRef.current?.scrollHeight -
            (chatWindowRef.current?.scrollTop + chatWindowRef.current?.clientHeight) <=
            48
        ) {
          shouldScrollRef.current = true;
        } else {
          shouldScrollRef.current = false;
        }
      };
      chatWindowRef.current.addEventListener('scrollend', scrollEndHandler);

      return () => {
        if (chatWindowRef.current) {
          chatWindowRef.current?.removeEventListener('scrollend', scrollEndHandler);
        }
      };
    }
  }, []);
  useEffect(() => {
    if (chatWindowRef.current && shouldScrollRef.current) {
      chatWindowRef.current.scrollTo({
        left: 0,
        top: chatWindowRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [chat]);
  return (
    <div
      className={`${styles.window} scroll ${name && name === 'manager' ? styles.manager : ''}`}
      ref={chatWindowRef}
    >
      {name && name !== 'manager' && (
        <div className={styles.intro}>
          <p className={styles.title}>
            {'Вы создали чат по'} &quot;{name}&quot;
          </p>
          <p className={styles.desc}>{'Менеджер уточнит все детали в ближайшее время'}</p>
        </div>
      )}

      {chat &&
        chat.messages.map((m, i): any => (
          <Fragment key={m.id}>
            {i === 0 && <DateBlock date={m.time} />}
            {m.type === 'message' && (
              <ChatMessage
                type={m.type}
                message={m.message}
                time={m.time}
                id={m.id}
                from={m.from}
                //@ts-ignore
                lastMsg={chat.messages.length === i + 1 || chat.messages[i + 1].from !== m.from}
              />
            )}
            {m.type === 'money' && (
              <ChatMoney
                id={m.id}
                from={m.from}
                status={m.status}
                time={m.time}
                type={m.type}
                amount={m.amount}
              />
            )}
            {m.type === 'rating' && <RatingMessage id={m.id} rate={m.rate} />}
            {m.type === 'donate' && <DonateMessage />}
            {m.type === 'contact-request' && <ContactRequestMessage time={m.time} />}
            {m.type === 'thanks-for-donation' && <ThanksForDonationMessage />}
            {m.type === 'contact' && (
              <ContactMessage
                firstName={m.firstName}
                age={m.age}
                number={m.number}
                hidden={m.hidden}
              />
            )}
            {m.type === 'selection' && <SelectionMessage selection={m} />}
            {chat.messages.length !== i + 1 &&
              new Date(m.time).setHours(0, 0, 0, 0) !==
                new Date(chat.messages[i + 1].time).setHours(0, 0, 0, 0) && (
                <DateBlock date={chat.messages[i + 1].time} />
              )}
          </Fragment>
        ))}
      {!chat && (
        <div className={styles['select-chat-block']}>
          <p>{'Выберите чат, чтобы начать общение'}</p>
        </div>
      )}
    </div>
  );
};
