import { UserRow } from '../../../entities/users/ui/UserRow';
import styles from './UserList.module.scss';

export const UserList = () => {
  const DetailsUsers = [
    {
      id: 0,
      registerDate: '2023-12-22T11:32:27.181Z',
      nickname: 'jsjdi_ooo',
      sum: 48000,
      status: 'Новичок (#560)',
      orders: '126',
      auctions: '126',
    },
    {
      id: 1,
      registerDate: '2023-12-22T11:32:27.181Z',
      nickname: 'jsjdi_ooo',
      sum: 48000,
      status: 'Новичок (#560)',
      orders: '126',
      auctions: '126',
    },
    {
      id: 2,
      registerDate: '2023-12-22T11:32:27.181Z',
      nickname: 'jsjdi_ooo',
      sum: 48000,
      status: 'Новичок (#560)',
      orders: '126',
      auctions: '126',
    },
    {
      id: 3,
      registerDate: '2023-12-22T11:32:27.181Z',
      nickname: 'jsjdi_ooo',
      sum: 48000,
      status: 'Новичок (#560)',
      orders: '126',
      auctions: '126',
    },
    {
      id: 4,
      registerDate: '2023-12-22T11:32:27.181Z',
      nickname: 'jsjdi_ooo',
      sum: 48000,
      status: 'Новичок (#560)',
      orders: '126',
      auctions: '126',
    },
    {
      id: 5,
      registerDate: '2023-12-22T11:32:27.181Z',
      nickname: 'jsjdi_ooo',
      sum: 48000,
      status: 'Новичок (#560)',
      orders: '126',
      auctions: '126',
    },
    {
      id: 6,
      registerDate: '2023-12-22T11:32:27.181Z',
      nickname: 'jsjdi_ooo',
      sum: 48000,
      status: 'Новичок (#560)',
      orders: '126',
      auctions: '126',
    },
    {
      id: 7,
      registerDate: '2023-12-22T11:32:27.181Z',
      nickname: 'jsjdi_ooo',
      sum: 48000,
      status: 'Новичок (#560)',
      orders: '126',
      auctions: '126',
    },
    {
      id: 8,
      registerDate: '2023-12-22T11:32:27.181Z',
      nickname: 'jsjdi_ooo',
      sum: 48000,
      status: 'Новичок (#560)',
      orders: '126',
      auctions: '126',
    },
    {
      id: 9,
      registerDate: '2023-12-22T11:32:27.181Z',
      nickname: 'jsjdi_ooo',
      sum: 48000,
      status: 'Новичок (#560)',
      orders: '126',
      auctions: '126',
    },
    {
      id: 10,
      registerDate: '2023-12-22T11:32:27.181Z',
      nickname: 'jsjdi_ooo',
      sum: 48000,
      status: 'Новичок (#560)',
      orders: '126',
      auctions: '126',
    },
    {
      id: 11,
      registerDate: '2023-12-22T11:32:27.181Z',
      nickname: 'jsjdi_ooo',
      sum: 48000,
      status: 'Новичок (#560)',
      orders: '126',
      auctions: '126',
    },
    {
      id: 12,
      registerDate: '2023-12-22T11:32:27.181Z',
      nickname: 'jsjdi_ooo',
      sum: 48000,
      status: 'Новичок (#560)',
      orders: '126',
      auctions: '126',
    },
    {
      id: 13,
      registerDate: '2023-12-22T11:32:27.181Z',
      nickname: 'jsjdi_ooo',
      sum: 48000,
      status: 'Новичок (#560)',
      orders: '126',
      auctions: '126',
    },
    {
      id: 14,
      registerDate: '2023-12-22T11:32:27.181Z',
      nickname: 'jsjdi_ooo',
      sum: 48000,
      status: 'Новичок (#560)',
      orders: '126',
      auctions: '126',
    },
  ];
  return (
    <ul className={styles.list}>
      {DetailsUsers.map(({ id, registerDate, nickname, sum, status, orders, auctions }) => (
        <UserRow
          key={id}
          id={id}
          registerDate={registerDate}
          nickname={nickname}
          sum={sum}
          status={status}
          orders={orders}
          auctions={auctions}
        />
      ))}
    </ul>
  );
};
