import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { memo, useState } from 'react';
import { DeletePhotoButton } from 'features/questionnaire/DeletePhotoButton';

import styles from './ImageContainer.module.scss';
import { ReactComponent as AddSquareIcon } from '../../../shared/assets/icons/addsquare.svg';
import { ImageContainerPropsI } from './ImagesContainer.props';

export const ImageContainer = memo(({ image }: ImageContainerPropsI) => {
  const [value, setValue] = useState('');
  return (
    <div className={styles.image_container}>
      <div className={styles.image}>
        {image ? (
          <img src={image} alt="girl_photo" />
        ) : (
          <div className={styles.placeholder_image}></div>
        )}
        <div className={styles.delete}>
          <DeletePhotoButton />
        </div>
      </div>
      <Button additionalClasses={styles.add_button} type={4}>
        <AddSquareIcon />
        <span>Добавить Фото в аватар</span>
      </Button>
      <Input
        setValue={setValue}
        value={value}
        tipAsPlaceholder
        additionalClasses={styles.input}
        type={2}
        tip="Телефон (не виден пользователям)"
      />
    </div>
  );
});
