// import { useAppSelector } from '@/app/store/hooks';
import { Button } from 'shared/ui/Button';
import { SubscribeBtn } from 'features/subscriptions/SubscribeBtn';

import styles from './SubscriptionControl.module.scss';
import { SubscriptionControlPropsT } from './SubscriptionControlProprsT';
enum SubscriptionType {
  'questionnaire-hot' = 'Hot 18+',
  'catalog' = 'Каталог',
}
export const SubscriptionControl = ({
  autoDebit,
  price,
  type,
  subscriptionEnd,
  subscriptionId,
  setModalInfo,
}: SubscriptionControlPropsT) => {
  // const { t } = useTranslation('subscription');
  // const { subscriptionsLoading } = useAppSelector((state) => state.subscriptions);
  return (
    <div className={styles.block}>
      <div className={styles.info}>
        <p>{SubscriptionType[type]}</p>
        <p>{price}€/месяц</p>
      </div>
      <div className={styles.info2}>
        <p>Следующее списание:</p>
        <p>{new Date(subscriptionEnd).toLocaleDateString('ru-Ru')}</p>
      </div>
      {autoDebit ? (
        <Button
          type={4}
          additionalClasses={`mt15 ${styles.btn}`}
          // disabled={subscriptionsLoading}
          onClick={() =>
            setModalInfo({
              name: SubscriptionType[type],
              subscriptionId,
              validUntil: new Date(subscriptionEnd).toLocaleDateString('ru-Ru'),
              show: true,
            })
          }
        >
          Отменить подписку
        </Button>
      ) : (
        <SubscribeBtn subscriptionId={subscriptionId} />
      )}
    </div>
  );
};
