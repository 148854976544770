import { TableCellPropsI } from './TableCell.props';
import styles from './TableCell.module.scss';
import { Stipulation } from '../../Stipulation';

export const TableCell = ({
  cell,
  handleUnlockEditingRow,
  id,
  currentRowId,
  type,
  rowData,
  setRowData,
}: TableCellPropsI) => {
  const isCurrentRowEditing = currentRowId === id;

  const getRenderContent = (cell: any) => {
    if (isCurrentRowEditing) {
      if (type === 'name') {
        return (
          <input
            type="text"
            className={styles.inputName}
            value={rowData?.name}
            onChange={({ target }) => setRowData({ ...rowData, name: target.value })}
          />
        );
      }
      if (type === 'text') {
        return (
          <textarea
            value={rowData?.text}
            onChange={({ target }) => setRowData({ ...rowData, text: target.value })}
            className={styles.textarea}
          >
            {cell}
          </textarea>
        );
      }
      if (type === 'period') {
        return (
          <input
            value={rowData?.period}
            onChange={({ target }) => setRowData({ ...rowData, period: target.value })}
            className={styles.inputText}
            type="text"
          />
        );
      }
      if (type === 'stipulation') {
        return (
          rowData && <Stipulation qParams={rowData} setQparams={setRowData} disabled={false} />
        );
      }
    }
    if (typeof cell === 'string' && !isCurrentRowEditing && type !== 'stipulation') {
      return cell;
    } else {
      return rowData && <Stipulation qParams={rowData} setQparams={setRowData} disabled={true} />;
    }
  };

  return cell === 'button' ? (
    <button onClick={() => handleUnlockEditingRow(id)} className={styles.cell}>
      {isCurrentRowEditing ? 'Сохранить' : 'Редактировать'}
    </button>
  ) : (
    <td className={styles.cell}>{getRenderContent(cell)}</td>
  );
};
