import { QuestionnairyItem } from 'entities/questionnaires/ui/QuestionnairesItem';

import styles from './QuestionneiresList.module.scss';
import { QuestionnairesListProps } from './QuestionneiresList.props';

export const QuestionnairesList = ({ questionnairesList }: QuestionnairesListProps) => {
  return (
    <div className={styles.list}>
      {questionnairesList.map(
        ({
          firstName,
          age,
          priceAccess,
          countLikes,
          countFavorites,
          isLiked,
          avatars,
          catalog,
          isFavorite,
          id,
          isBuyed,
        }) => (
          <QuestionnairyItem
            key={id}
            id={id}
            isBuyed={isBuyed}
            isFavorite={isFavorite}
            avatars={avatars}
            priceAccess={priceAccess}
            age={age}
            countLikes={countLikes}
            catalog={catalog}
            firstName={firstName}
            countFavorites={countFavorites}
            isLiked={isLiked}
          />
        )
      )}
    </div>
  );
};
