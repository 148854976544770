import { useSwither } from 'shared/hooks/useSwither';
import { useTimer } from 'shared/hooks/useTimer';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styles from './ImagesSwitcher.module.scss';
import { ImagesSwitherProps } from './ImagesSwitcher.props';
import { ImagesList } from './ImageList';
import { Badge } from '../Badge';
import { Button } from '../Button';

export const ImagesSwitcher = ({
  catalog,
  isBuyed = true,
  images,
  isHover,
  badgeClasses,
  height,
  width,
  id,
}: ImagesSwitherProps) => {
  const {
    currentImageIndex,
    handleButtonClick,
    handleButtonMouseEnter,
    handleTouchMove,
    handleTouchStart,
  } = useSwither(images);

  // const { hours, minutes, remainingSeconds } = useTimer(3600);

  const getRenderContent = () => {
    return (
      <>
        {catalog && (
          <Badge
            additionalClasses={`${styles.badge} ${badgeClasses || ''}`}
            value={catalog?.title['ru']}
            type={catalog?.classname}
          />
        )}
        {/* <Badge
          additionalClasses={`${styles.badge} ${styles.timer_badge}`}
          value={`Доступна: ${hours}:${minutes}:${remainingSeconds}`}
        ></Badge> */}
        <ImagesList
          images={images}
          currentImageIndex={currentImageIndex}
          width={width}
          height={height}
        />
        {isHover ? (
          <div className={styles.hover_content}>
            {isBuyed ? (
              <span className="flex align-center gap5">
                {/* <EyeIcon /> */}
                Анкета
              </span>
            ) : (
              catalog && (
                <Button additionalClasses={styles.buy_button} type={catalog?.classname}>
                  Купить 7000$
                </Button>
              )
            )}
          </div>
        ) : null}
        <div className={styles.enter_coitaner}>
          {images.map((_, index) => (
            <div
              onMouseEnter={() => handleButtonMouseEnter(index)}
              className={styles.enter_container_item}
              key={index}
            ></div>
          ))}
        </div>
        {images.length > 1 && (
          <div className={styles.button_container}>
            {images.map((_, index) => (
              <button
                className={classNames(
                  styles.button,
                  index === currentImageIndex && styles.button_active
                )}
                key={index}
                onClick={(event) => handleButtonClick(event, index)}
              ></button>
            ))}
          </div>
        )}
      </>
    );
  };

  return isBuyed ? (
    <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
      <Link to={`/catalogs/${catalog?.id}/${id}`} className={styles.image_wrapper}>
        {getRenderContent()}
      </Link>
    </div>
  ) : (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      className={styles.image_wrapper}
    >
      {getRenderContent()}
    </div>
  );
};

//
