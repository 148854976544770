import { Input } from 'shared/ui/Input';
import { useState } from 'react';
import { Checkbox } from 'shared/ui/Checkbox';
import { Button } from 'shared/ui/Button';
import { notificationsBotApi } from 'shared/config/api/api';

import styles from './CreateTargetNotification.module.scss';
import { CreateTargetNotificationPropsI } from './CreateTargetNotification.props';

export const CreateTargetNotification = ({ token }: CreateTargetNotificationPropsI) => {
  const [qParams, setQparams] = useState({
    name: '',
    text: '',
    target: '',
  });
  const [isCheckedAll, setIsCheckedAll] = useState(true);
  const [isCheckedTarget, setIsCheckedTarget] = useState(false);
  const [errorTarget, setErrorTarget] = useState(false);

  const [errors, setErrors] = useState({
    nameError: false,
    textError: false,
    targetError: false,
  });

  const getIncludesValue = () => {
    let newErrors = { ...errors };

    if (!qParams.name) {
      newErrors = { ...newErrors, nameError: true };
    } else {
      newErrors = { ...newErrors, nameError: false };
    }
    if (!qParams.text) {
      newErrors = { ...newErrors, textError: true };
    } else {
      newErrors = { ...newErrors, textError: false };
    }
    if (!qParams.target) {
      newErrors = { ...newErrors, targetError: true };
    } else {
      newErrors = { ...newErrors, targetError: false };
    }

    setErrors(newErrors);
  };

  const handleSubmit = () => {
    if (qParams.target.startsWith('@') && qParams.target !== 'ALL') {
      getIncludesValue();
      setErrorTarget(false);
      notificationsBotApi.createBotTargetNotification(qParams, token);
    } else {
      getIncludesValue();
      setErrorTarget(true);
      notificationsBotApi.createBotTargetNotification(qParams, token);
    }
  };

  const handleClickCheckedAll = () => {
    setIsCheckedTarget(false);
    setIsCheckedAll(true);
    setQparams({ ...qParams, target: 'ALL' });
  };

  const handleClickCheckedTarget = () => {
    setIsCheckedTarget(true);
    setIsCheckedAll(false);
    setQparams({ ...qParams, target: '' });
  };
  return (
    <div className={styles.container}>
      <div>
        <Input
          type={2}
          tip="Имя отправителя"
          tipAsPlaceholder
          value={qParams.name}
          setValue={(event) => setQparams({ ...qParams, name: event })}
        />
        {errors.nameError && <p>Поле не может быть пустым</p>}
      </div>
      <div>
        <Input
          tip="Текст уведомления"
          tipAsPlaceholder
          type={2}
          value={qParams.text}
          setValue={(event) => setQparams({ ...qParams, text: event })}
        />
        {errors.textError && <p>Поле не может быть пустым</p>}
      </div>
      <ul className={styles.target_container}>
        <li className={styles.target_item}>
          <p className={styles.text}>По id</p>
          <Checkbox checked={isCheckedTarget} onClick={handleClickCheckedTarget} />
        </li>
        <li className={styles.target_item}>
          <p className={styles.text}>Всем</p>
          <Checkbox checked={isCheckedAll} onClick={handleClickCheckedAll} />
        </li>
      </ul>
      {isCheckedTarget && (
        <div className={styles.target_error_container}>
          <Input
            setValue={(event) => setQparams({ ...qParams, target: event })}
            tip="Telegram id"
            tipAsPlaceholder
            type={2}
            value={qParams.target}
          />
          {errorTarget && <p className={styles.errorText}>Ник должен начинаться с @</p>}
          {errors.targetError && <p>Поле не может быть пустым</p>}
        </div>
      )}
      <Button onClick={handleSubmit} type={4}>
        Отправить
      </Button>
    </div>
  );
};
