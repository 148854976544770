import { ReactComponent as UsersIcon } from '../../shared/assets/icons/users.svg';
import { ReactComponent as CatalogsIcon } from '../../shared/assets/icons/catalogs.svg';
import { ReactComponent as ChatIcon } from '../../shared/assets/icons/chat.svg';
// import { ReactComponent as FavoritesIcon } from '../../shared/assets/icons/favorites.svg';
import { ReactComponent as AuctionsIcon } from '../../shared/assets/icons/auctions.svg';

export const DetailsNavLink = [
  {
    id: 0,
    link: '/users',
    name: 'Пользователи',
    Icon: UsersIcon,
  },
  {
    id: 1,
    link: '/catalogs',
    name: 'Каталоги',
    Icon: CatalogsIcon,
  },
  {
    id: 2,
    link: '/chat',
    name: 'Чат',
    Icon: ChatIcon,
    badge: 2,
  },

  {
    id: 5,
    link: '/auctions',
    name: 'Аукционы',
    Icon: AuctionsIcon,
  },
];
