import UserIcon from 'shared/assets/mock/user.png';
import { ReactComponent as AnchoredIcon } from 'shared/assets/icons/anchored.svg';
import { ReactComponent as StarIcon } from 'shared/assets/icons/star1.svg';
import { SyntheticEvent, useState } from 'react';

import { ChatItemListPropsI } from './ChatItemList.props';
import styles from './ChatItemList.module.scss';

export const ChatItemList = ({
  name,
  isAnchored,
  isFavorites,
  dialogs,
  unreadMessage,
  id,
  time,
  handleClickCurrentDialog,
}: ChatItemListPropsI) => {
  const [anchored, setAnchored] = useState(isAnchored);
  const [favorites, setFavorites] = useState(isFavorites);

  const handleClickAnchored = (event: SyntheticEvent) => {
    event.stopPropagation();
    setAnchored((test) => !test);
  };

  const handleClickFavorites = (event: SyntheticEvent) => {
    event.stopPropagation();
    setFavorites((prev) => !prev);
  };
  return (
    <button onClick={() => handleClickCurrentDialog(id)} className={styles.button}>
      <img src={UserIcon} alt="user_photo" />
      <div className={styles.info}>
        <p className={styles.name}>{name}</p>
        <p className={styles.dialogs}>{dialogs} диалога</p>
      </div>
      <div className={styles.buttons}>
        <button onClick={handleClickAnchored}>
          <AnchoredIcon className={`${styles.anchored} ${anchored ? styles.icon_active : ''}`} />
        </button>
        <span className={styles.time}>{time}</span>
        <button onClick={handleClickFavorites}>
          <StarIcon className={`${styles.star} ${favorites ? styles.icon_active : ''}`} />
        </button>
        {unreadMessage ? <p className={styles.unread_message}>{unreadMessage}</p> : null}
      </div>
    </button>
  );
};
