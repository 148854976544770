import { useEffect, useState } from 'react';
import { notificationsBotApi } from 'shared/config/api/api';
import { NotificationBotI } from 'pages/AdminPage/interfaces';
import { Button } from 'shared/ui/Button';

import styles from './NotificationTable.module.scss';
import { TableRow } from './TableRow';

export interface NotificationTablePropsI {
  token: string;
}

export const NotificationTable = ({ token }: NotificationTablePropsI) => {
  const [params, setParams] = useState<NotificationBotI[]>([]);

  useEffect(() => {
    notificationsBotApi.getAllNotificationBot(token).then((res) => setParams(res.data));
  }, [token]);

  const handleDelteNotification = async (id?: number) => {
    if (id) {
      await notificationsBotApi.deleteNotification(id, token);
    }
    await notificationsBotApi.getAllNotificationBot(token).then((res) => setParams(res.data));
  };

  return (
    <table className={styles.container}>
      <thead className={styles.header}>
        <div className={styles.item}>Имя отправителя</div>
        <div className={styles.item}>Текст уведомления</div>
        <div className={styles.item}>Время в минутах</div>
        <div className={styles.item}>Условия</div>
      </thead>
      <tbody>
        {params.map(({ name, text, period, stipulation, id }) => (
          <div className={styles.wrapper}>
            <TableRow
              key={id}
              token={token}
              name={name}
              text={text}
              period={period}
              stipulation={stipulation}
              rowId={id}
              setParams={setParams}
              params={params}
            />
            <Button
              onClick={() => handleDelteNotification(id)}
              additionalClasses={styles.button}
              type={1}
            >
              Удалить
            </Button>
          </div>
        ))}
      </tbody>
    </table>
  );
};
