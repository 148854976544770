import { AddNewsBlock } from '../AddNewsBlock/AddNewsBlock';
import { NewsNavigations } from '../NewsNavigations/NewsNavigations';
import { Post } from '../Post/Post';
import styles from './NewsBlock.module.scss';

const mockData = [
  {
    text: 'Lorem ipsum dolor sit amet consectetur. Non id sed eget mauris tincidunt metus sed. Pellentesque commodo turpis pellentesque cursus consectetur enim fermentum etiam. Sit commodo cras ut vitae et condimentum. Et lorem neque nulla ut aliquet. Etiam tortor purus in duis bibendum nisl aenean vestibulum aliquam. Pellentesque at nulla bibendum praesent enim.',
    emotions: { kiss: 7, smile: 20, heart: 34 },
  },
  {
    text: 'test',
    emotions: { kiss: 17, smile: 20, heart: 7 },
  },
];

export const NewsBlock = () => {
  return (
    <div className={styles.container}>
      <div>
        <AddNewsBlock />
        <div className={styles.posts}>
          {mockData.map(({ text, emotions }) => (
            <Post key={text} emotions={emotions} text={text} />
          ))}
        </div>
      </div>
      <NewsNavigations />
    </div>
  );
};
