import { useState } from 'react';
import { Badge } from 'shared/ui/Badge';
import classNames from 'classnames';

import styles from './QuickFilter.module.scss';

export const QuickFilters = () => {
  const [quickFilters, setQuickFilters] = useState<number[]>([]);
  const filters = [
    {
      id: 1,
      title: 'GOLD',
    },
    {
      id: 2,
      title: 'PLATINUM',
    },
    {
      id: 3,
      title: 'EXCLUSIVE',
    },
    {
      id: 4,
      title: 'PREMIUM',
    },
    {
      id: 5,
      title: 'VIP GIRLS',
    },
    {
      id: 6,
      title: 'DUBAI',
    },
    {
      id: 7,
      title: 'ДЕВСТВЕННИЦЫ',
    },
  ];

  const handleClickSetCatalog = (catalog: number) => {
    const isCatalogIncluded = quickFilters.includes(catalog);
    if (isCatalogIncluded) {
      setQuickFilters((prev) => prev.filter((item) => item !== catalog));
    } else {
      setQuickFilters((prev) => [...prev, catalog]);
    }
  };

  return (
    <ul className={styles.badges}>
      {filters.map(({ id, title }) => (
        <li key={id}>
          <button onClick={() => handleClickSetCatalog(id)}>
            <Badge
              value={title}
              additionalClasses={`${classNames(
                styles.badge,
                quickFilters.includes(id) ? styles.active_badge : ''
              )}`}
            />
          </button>
        </li>
      ))}
    </ul>
  );
};
