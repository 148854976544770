import { useRef, useState } from 'react';
import { FormType } from 'shared/types/FormType/FormType';
import { CalendarCustom } from 'shared/ui/Calendar';
import { useForm } from 'shared/hooks/useForm/useForm';
import { Button } from 'shared/ui/Button';

import styles from './AddNewsBlock.module.scss';
import { ReactComponent as CameraSVG } from '../../../shared/assets/icons/camera.svg';
import { ImagesContainer } from './ImagesContainer/ImagesContainer';

export const AddNewsBlock = () => {
  const [value, setValue] = useState('');

  const initialForm: FormType = {
    publish: {
      type: 'calendar',
      id: 0,
      isValid: false,
      placeholder: 'Запланировать публикацию',
      isRequired: false,
      value: '',
    },
  };

  const { form, handleChangeForm } = useForm(initialForm);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };
  return (
    <div className={styles.container}>
      <ImagesContainer />
      <textarea
        ref={textareaRef}
        className={styles.textarea}
        value={value}
        placeholder="Добавить новость..."
        onChange={handleChange}
      ></textarea>
      <div className={styles.buttons_container}>
        <button className={styles.upload}>
          <CameraSVG />
        </button>
        <CalendarCustom
          title={form['publish'].placeholder}
          date={form['publish'].value}
          setDate={(date: any) => handleChangeForm('publish', date)}
          isIcon
        />
        <Button type={3}>Опубликовать</Button>
      </div>
    </div>
  );
};
