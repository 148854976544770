import { useDispatch } from 'react-redux';

import { useAppSelector } from '../../app/store';
import styles from './Header.module.scss';
import { authLogout } from '../../features/auth/authSlice';
import { Button } from '../../shared/ui/Button';

export const Header = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  const handleLogout = () => {
    dispatch(authLogout() as any);
  };

  return (
    <header className={styles.header}>
      <nav className={styles.navbar}>
        <div className={styles.brand}>Realtop HEADER</div>
        {isAuthenticated && (
          <Button type={4} onClick={handleLogout}>
            Logout
          </Button>
        )}
      </nav>
    </header>
  );
};
