import { CatalogItem } from 'entities/catalogs/ui/CatalogItem';
import { QuestionnairyItemV2 } from 'entities/questionnaires/ui/QuestionnaryItemV2';

import { CatalogSubscriptionDescT, QuestionnaireHotPhotosDescT, SubscriptionT } from '../../types';

export function generateSubscriptionCard(s: SubscriptionT<any>) {
  switch (s.type) {
    case 'catalog': {
      const s2: SubscriptionT<CatalogSubscriptionDescT> = s;
      return (
        <CatalogItem
          id={s2.id}
          classname={s2.details.classname}
          isBuyed={true}
          countQuestionnaires={s2.details.countQuestionnaires}
          title={s2.details.title}
          questionnaires={s2.details.questionnaires}
          subtitle={s2.details.subtitle}
          type="subscription"
          lastUpdate={s2.details.lastUpdate}
        />
      );
    }
    case 'questionnaire-hot': {
      const s2: SubscriptionT<QuestionnaireHotPhotosDescT> = s;
      return (
        <QuestionnairyItemV2
          key={s2.id}
          name={s2.details.firstName}
          age={s2.details.age}
          avatar={s2.details.avatars[0]}
          catalogName={s2.details.catalog.title}
          classname={s2.details.catalog.classname}
          city={s2.details.city.name}
          country={s2.details.city.country.name}
          id={s2.details.id}
        />
      );
    }
    default:
      return <></>;
  }
}
