import { Modal } from 'shared/ui/Modal';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import classNames from 'classnames';
import { useFileUpload } from 'shared/hooks/useFileUpload';
import { useEffect, useState } from 'react';
import { Loader } from 'shared/ui/Loader';
import { FileType } from 'shared/types/FileType';

import styles from './UploadModal.module.scss';
import { ReactComponent as AddSquareIcon } from '../../shared/assets/icons/addsquare.svg';
import { UploadlModalPropsI } from './UploadModal.props';
import { FileList } from './FileList';

export const UploadModal = ({
  isVisible,
  close,
  isPutPrice = false,
  title,
  dropzoneText,
  handleSetFiles,
}: UploadlModalPropsI) => {
  const [files, setFiles] = useState<FileType[]>([]);
  const {
    handeFileChange,
    processingFileName,
    handleClickRemoveFile,
    handleDrop,
    handleDragLeave,
    handleDragOver,
  } = useFileUpload(setFiles, files);

  const handleSubmite = (files: FileType[]) => {
    handleSetFiles(files);
    close();
  };

  useEffect(() => {
    if (!isVisible) {
      setFiles([]);
    }
  }, [isVisible]);

  return (
    <Modal isVisible={isVisible} close={close}>
      <p className={styles.title}>{title}</p>
      {processingFileName ? (
        <div className={styles.loading_container}>
          <Loader />
          <p>{processingFileName}</p>
        </div>
      ) : (
        <FileList imagesArray={files} handleRemove={handleClickRemoveFile} />
      )}
      <div className={styles.dropzone_container}>
        <label
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          className={classNames(
            styles.dropzone,
            files.length && !processingFileName ? styles.with_images : styles.without_images
          )}
          htmlFor="fileUploader"
        >
          <AddSquareIcon className={styles.icon} />
          <span className={styles.dropzone_text}>{dropzoneText}</span>
          <input
            id="fileUploader"
            className={styles.hidden}
            type="file"
            onClick={(event) => event.stopPropagation()}
            onChange={handeFileChange}
            multiple
          />
        </label>
      </div>
      {isPutPrice && (
        <div className={styles.input_container}>
          <Input
            additionalClasses={styles.input}
            tip={'Назначьте цену'}
            tipAsPlaceholder={true}
            value={''}
            type={2}
          />
          <span className={styles.dollar}>$</span>
        </div>
      )}
      <div className={styles.button_container}>
        <Button onClick={close} type={4}>
          Отменить
        </Button>
        <Button onClick={() => handleSubmite(files)} type={3}>
          Добавить
        </Button>
      </div>
    </Modal>
  );
};
