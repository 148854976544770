// import { useTranslation } from 'next-i18next';

// import { useAppDispatch, useAppSelector } from '@/app/store/hooks';
// import { changeSubscription } from '@/entities/subscriptions/api';
import { Button } from 'shared/ui/Button';

export const SubscribeBtn = ({ subscriptionId }: { subscriptionId: number }) => {
  // const { t } = useTranslation('subscription');
  // const dispatch = useAppDispatch();
  // const { subscriptionsLoading } = useAppSelector((state) => state.subscriptions);
  // const changeSubscriptionFn = () => {
  //   dispatch(changeSubscription({ subscriptionId, subscribed: true }));
  // };
  return (
    <Button
      type={4}
      additionalClasses="mt15"
      // disabled={subscriptionsLoading}
      // onClick={changeSubscriptionFn}
    >
      Подписаться
    </Button>
  );
};
