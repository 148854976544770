import styles from './CommonBankWidget.module.scss';
import { ReactComponent as PurseIcon } from '../../../shared/assets/icons/purse.svg';
import { CommonBankWidgetI } from './CommonBankWidget.porps';

export const CommonBankWidget = ({ color = 'default', price, text }: CommonBankWidgetI) => {
  return (
    <div className={`${styles.container} ${styles[color]}`}>
      <div className={styles.icon_container}>
        <PurseIcon className={styles.icon} />
      </div>
      <div className={styles.text_container}>
        <p className={styles.price}>{price?.toLocaleString('ru-RU')}$</p>
        <span className={styles.subtitle}>Общий банк</span>
      </div>
    </div>
  );
};
