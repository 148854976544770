import classNames from 'classnames';
import { Catalog } from 'pages/interface';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { catalog, fecthChatIds, telegramBotImages } from 'shared/config/api/api';
import { processImage } from 'shared/helpers/proccesImage';
import { CatalogRow } from 'widgets/Catalogs/CatalogRow';
import { TelegramBotImagesType } from 'widgets/Catalogs/CatalogRow/CatalogRow.props';

import styles from './AdminBotTab.module.scss';
import { AdminBotTabProps } from './AdminBotTab.props';

export const AdminBotTab = ({ token }: AdminBotTabProps) => {
  const [catalogs, setCatalogs] = useState<Catalog[]>([]);
  const [chatIds, setChatIds] = useState([]);
  const [success, setSuccess] = useState(false);
  const [telegramBotImagesArray, setTelegramBotImagesArray] = useState<TelegramBotImagesType[]>([]);
  const [image, setImage] = useState<string>('');

  const filePicker = useRef<HTMLInputElement | null>(null);

  const handlePick = () => {
    if (filePicker.current) {
      filePicker.current.click();
    }
  };

  const currentImageCatalog = telegramBotImagesArray.find(({ typeId }: any) => typeId === null);

  const handleUploadImage = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      try {
        const croppedImage = await processImage(file);
        setImage(croppedImage);
        telegramBotImages.changeCurrentTelegramBotImage(
          currentImageCatalog?.id,
          croppedImage,
          token
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onlyCatalogImagesArray = telegramBotImagesArray.filter(({ type }) => type === 'catalog');

  const fetchAllCatalogs = async () => {
    try {
      const { data } = await catalog.getCatalogs();
      const allCatalogs = data.catalogs;
      setCatalogs(allCatalogs);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTelegramBotImages = async () => {
    try {
      const { data } = await telegramBotImages.getTelegramBotImages(token);
      const allImagesBot = data.telegramBotImages;
      setTelegramBotImagesArray(allImagesBot);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllCatalogs();
    fecthChatIds(setChatIds, token, setSuccess);
    fetchTelegramBotImages();
  }, [token]);

  return (
    <div>
      <ul
        style={{ marginBottom: 15 }}
        className={classNames(!success ? styles.header_table : styles.header_table_unauth)}
      >
        <li style={success ? { marginLeft: 40 } : { margin: 0 }} className={styles.header_item}>
          Текущая цена
        </li>
        <li className={styles.header_item}>Новая цена</li>
        {success ? <li className={styles.header_item}>ID чата с каталогом</li> : null}
        <li className={styles.header_item}>Предзаказ</li>
      </ul>
      <ul className={styles.catalog_list}>
        {catalogs.map((catalog) => (
          <CatalogRow
            setChatIds={setChatIds}
            token={token}
            key={catalog.id}
            preOrder={catalog.preOrder}
            id={catalog.id}
            title={catalog.title.ru}
            price={catalog.price / 100}
            chatIds={chatIds}
            telegramBotImagesArray={onlyCatalogImagesArray}
          />
        ))}
      </ul>
      {success ? (
        <div className={styles.image_container}>
          <p>Редактировать начальное изображение</p>
          <div className={styles.image_content}>
            <img
              className={styles.image}
              src={image || currentImageCatalog?.base64}
              alt="start_image"
            />
            <button onClick={handlePick} className={styles.upload_button}>
              изменить
            </button>
          </div>
          <input
            ref={filePicker}
            onChange={handleUploadImage}
            type="file"
            className={styles.hidden}
          />
        </div>
      ) : null}
    </div>
  );
};
