export enum UserType {
  User = 'userComment',
  Another = 'anotherUserComment',
}

export interface CommentsViewPropsI {
  commentsData: {
    name?: string;
    type: UserType.Another | UserType.User;
    message: string;
    id: number;
    time: string;
  }[];
}
