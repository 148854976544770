import { ChangeEvent, useState } from 'react';

import styles from './IdsList.module.scss';
import { catalog } from '../../../shared/config/api/api';

export const IdsList = ({ index, telegramChatId, handleDeleteIds, id, token }: any) => {
  const [newId, setNewId] = useState(telegramChatId);

  const handleChangeInputValue = async (event: ChangeEvent<HTMLInputElement>) => {
    const updatedId = event.target.value;
    setNewId(updatedId);
    try {
      await catalog.updateChatId(updatedId, token, id);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <li className={styles.itemIds}>
      <input
        onChange={handleChangeInputValue}
        className={styles.input}
        type="text"
        placeholder="id чата"
        value={newId}
      />
      {index > 0 && (
        <button
          onClick={() => handleDeleteIds(index, telegramChatId, id)}
          className={styles.add_new_ids_button}
        >
          удалить
        </button>
      )}
    </li>
  );
};
