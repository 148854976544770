import { CreateNotification } from 'pages/AdminPage/components/CreateNotification';
import { NotificationTable } from 'pages/AdminPage/components/NotificationTable';
import { CreateTargetNotification } from 'pages/AdminPage/components/CreateTargetNotification';

import styles from './NotificationsBot.module.scss';
import { NotificationsBotPropsI } from './NotificationsBot.props';

export const NotificationsBot = ({ token }: NotificationsBotPropsI) => {
  return (
    <div className={styles.container}>
      <CreateNotification token={token} />
      <NotificationTable token={token} />
      <CreateTargetNotification token={token} />
      <div></div>
    </div>
  );
};
